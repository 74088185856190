import { gql } from '@apollo/client';
import { parseCoinOption, parseCreatedAtObj } from '@utils/index';
import { Status } from '../types';

export const IMPORT_LIST = gql`
  query adminImportSwapsInfo(
    $created_at: DateTimeRange
    $keyword: String
    $swap_status: RequestSwapStatus
    $perPage: Int = 10
    $page: Int = 1
    $searchOption: String
    $coinOption: Int
    $pageId: Int!
  ) {
    adminImportSwapAmount(
      created_at: $created_at
      swap_status: $swap_status
      option: $searchOption
      keyword: $keyword
      coin_id: $coinOption
      admin_page_id: $pageId
    ) {
      total_count
      completed_count
      cancel_count
      export_amount
      import_amount
      fee
    }

    adminImportSwaps(
      created_at: $created_at
      swap_status: $swap_status
      option: $searchOption
      keyword: $keyword
      coin_id: $coinOption
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
      admin_page_id: $pageId
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        order_no
        partner_order_no
        email
        partner_id
        original_amount
        amount
        fee
        reward
        swap_status
        acronym
        created_at
        canceled_at
      }
    }
  }
`;

export const EXPORT_LIST = gql`
  query adminExportSwapsInfo(
    $created_at: DateTimeRange
    $keyword: String
    $swap_status: RequestSwapStatus
    $perPage: Int = 10
    $page: Int = 1
    $searchOption: String
    $coinOption: Int
    $pageId: Int!
  ) {
    adminExportSwapAmount(
      created_at: $created_at
      swap_status: $swap_status
      option: $searchOption
      keyword: $keyword
      coin_id: $coinOption
      admin_page_id: $pageId
    ) {
      total_count
      completed_count
      cancel_count
      export_amount
      import_amount
      reward_amount
      fee
    }

    adminExportSwaps(
      created_at: $created_at
      swap_status: $swap_status
      option: $searchOption
      keyword: $keyword
      coin_id: $coinOption
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
      admin_page_id: $pageId
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        order_no
        partner_order_no
        email
        partner_id
        original_amount
        amount
        fee
        swap_status
        acronym
        reward
        created_at
        canceled_at
      }
    }
  }
`;

interface TxParams {
  startDate: Date | null;
  endDate: Date | null;
  keyword: string;
  status: Status;
  searchOption: string;
  coinOption: string;
  perPage: number;
  page: number;
  pageId: number;
}

// 날짜 미설정시 query문
export const makeSwapVariables = ({
  startDate,
  endDate,
  keyword,
  status,
  coinOption,
  searchOption,
  perPage,
  page,
  pageId,
}: TxParams) => {
  return !startDate || !endDate
    ? {
        keyword,
        swap_status: status,
        coinOption: parseCoinOption(coinOption),
        searchOption,
        perPage,
        page,
        pageId,
      }
    : {
        created_at: parseCreatedAtObj(startDate, endDate),
        keyword: keyword,
        swap_status: status,
        coinOption: parseCoinOption(coinOption),
        searchOption: searchOption,
        perPage: perPage,
        page,
        pageId,
      };
};
