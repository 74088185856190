import { gql } from '@apollo/client';

const NOTICE_LIST = gql`
  query ($locale: LocaleStatus, $page: Int, $perPage: Int) {
    notices(locale: $locale, page: $page, first: $perPage) {
      data {
        id
        locale
        subject
        content
        is_active
        is_fixed
        created_at
      }
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
    }
  }
`;

const GET_NOTICE = gql`
  query ($id: ID) {
    notice(id: $id) {
      id
      subject
      content
      is_fixed
      is_active
      locale
      created_at
      images {
        id
        url
      }
      thumbnail {
        id
        url
      }
      previous {
        id
        subject
      }
      next {
        id
        subject
      }
    }
  }
`;

const CREATE_NOTICE = gql`
  mutation ($input: CreateNoticeInput!) {
    createNotice(input: $input) {
      id
      subject
      content
      is_fixed
      is_popup
      created_at
      updated_at
      images {
        id
        url
      }
      thumbnail {
        id
        url
      }
    }
  }
`;

const UPDATE_NOTICE = gql`
  mutation ($input: UpdateNoticeInput!) {
    updateNotice(input: $input) {
      id
      subject
      content
      is_fixed
      updated_at
      images {
        id
        url
      }
      thumbnail {
        id
        url
      }
      locale
      previous {
        id
        subject
      }
      next {
        id
        subject
      }
    }
  }
`;

const DELETE_NOTICE = gql`
  mutation ($id: ID!) {
    deleteNotice(id: $id) {
      id
      subject
    }
  }
`;

const SEARCH_NOTICES = gql`
  query (
    $locale: LocaleStatus
    $is_active: Boolean
    $page: Int!
    $where: QueryNoticeSearchWhereWhereConditions!
    $perPage: Int
  ) {
    noticeSearch(
      locale: $locale
      is_active: $is_active
      where: $where
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
      }
      data {
        id
        locale
        subject
        content
        is_fixed
        is_active
        created_at
      }
    }
  }
`;

const UPLOAD_THUMBNAIL = gql`
  mutation ($file: Upload!) {
    uploadThumbnail(file: $file) {
      id
      url
    }
  }
`;

interface noticeParams {
  listLocale: string;
  isActive: string;
  currentPage: number;
  perPage: number;
  selectedSearchOption: string;
  keyword: string;
}

type WhereType =
  | { column: string; operator: string; value: string }
  | { OR: { column: string; operator: string; value: string }[] };

export const constructWhere = (selectedSearchOption: string, keyword: string) => {
  let whereObject: WhereType | undefined = undefined;

  if (selectedSearchOption === 'SUBJECT') {
    whereObject = { column: 'SUBJECT', operator: 'LIKE', value: `%${keyword}%` };
  } else if (selectedSearchOption === 'CONTENT') {
    whereObject = { column: 'CONTENT', operator: 'LIKE', value: `%${keyword}%` };
  } else if (selectedSearchOption === 'OR') {
    whereObject = {
      OR: [
        { column: 'SUBJECT', operator: 'LIKE', value: `%${keyword}%` },
        { column: 'CONTENT', operator: 'LIKE', value: `%${keyword}%` },
      ],
    };
  }

  return whereObject;
};

export const makeNoticeVariables = ({
  listLocale,
  isActive,
  currentPage,
  perPage,
  selectedSearchOption,
  keyword,
}: noticeParams) => {
  const is_active = isActive === 'ALL' ? null : isActive === 'ACTIVE' ? true : false;
  const locale = listLocale === 'ALL' ? null : listLocale;
  const where = constructWhere(selectedSearchOption, keyword);

  let variables;

  if (is_active !== null && locale) {
    variables = { is_active, locale, page: currentPage, perPage, where };
  }

  if (is_active === null && locale) {
    variables = { locale, page: currentPage, perPage, where };
  }

  if (is_active !== null && !locale) {
    variables = { is_active, page: currentPage, perPage, where };
  }

  if (is_active === null && !locale) {
    variables = { page: currentPage, perPage, where };
  }

  return variables;
};

export {
  CREATE_NOTICE,
  DELETE_NOTICE,
  GET_NOTICE,
  NOTICE_LIST,
  SEARCH_NOTICES,
  UPDATE_NOTICE,
  UPLOAD_THUMBNAIL,
};
