import { modalState } from '@store/modalState';
import { useRecoilValue } from 'recoil';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import ErrorModal from './ErrorModal';

export const MODAL_TYPES = {
  confirm: 'confirmModal',
  alert: 'alertModal',
  error: 'errorModal',
};

const MODALS = {
  [MODAL_TYPES.confirm]: ConfirmModal,
  [MODAL_TYPES.alert]: AlertModal,
  [MODAL_TYPES.error]: ErrorModal,
};

const Modals = () => {
  const modals = useRecoilValue(modalState);

  if (modals.length === 0) {
    return null;
  }

  return (
    <div id="recoil-modal">
      {modals.map((modal) => {
        const ModalComponent = MODALS[modal.type];
        return <ModalComponent key={modal.idx} {...modal} />;
      })}
    </div>
  );
};

export default Modals;
