import { useEffect } from 'react';
import { Paths } from '@pages/Router';
import { getToken, isOverExpiredAt, removeLoginInfo } from '@utils/token';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isLoggedInSelector, userInfoState } from '@store/userState';
import { useLazyQuery } from '@apollo/client';
import { CURRENT_USER } from '@graphql/user';

const LoginHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const setUserInfo = useSetRecoilState(userInfoState);
  const [getUserInfo] = useLazyQuery(CURRENT_USER, {
    onCompleted: ({ currentUser }) => setUserInfo(currentUser),
  });

  useEffect(() => {
    // 엑세스토큰 없는경우 블락킹 리다이렉트
    if (!getToken()) {
      navigate(Paths.Login);
    }

    // 리프레시 기간 만료된경우 로그아웃 처리
    if (pathname !== Paths.Login) {
      if (isOverExpiredAt()) {
        alert('토큰이 만료 되었습니다.');
        removeLoginInfo();
        navigate(Paths.Login);
      }
    }

    // 관리자 권한 정보 받아와서 유지
    if (!isLoggedIn && getToken()) {
      getUserInfo();
    }
  }, [navigate, pathname]);
};

export default LoginHandler;
