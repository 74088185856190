import { DefaultValue, atomFamily, selectorFamily } from 'recoil';
import { Status } from '../../types';

export const perPageFamily = atomFamily<number, string>({
  key: 'pageFamily/perPage',
  default: 10,
});

export const currentPageFamily = atomFamily<number, string>({
  key: 'pageFamily/currentPage',
  default: 1,
});

export const pageInfoFamily = selectorFamily({
  key: 'pageFamily/pageInfo',
  get:
    (id: string) =>
    ({ get }) => {
      const perPage = get(perPageFamily(id));
      const currentPage = get(currentPageFamily(id));
      return { perPage, currentPage };
    },
});

export const tStartDateFamily = atomFamily<Date | null, string>({
  key: 'tableFamily/StartDate',
  default: null,
});

export const tEndDateFamily = atomFamily<Date | null, string>({
  key: 'tableFamily/EndDate',
  default: null,
});

export const tKeywordFamily = atomFamily<string, string>({
  key: 'tableFamily/Keyword',
  default: '',
});

export const tStatusFamily = atomFamily<Status, string>({
  key: 'tableFamily/Status',
  default: 'ALL',
});

export const tSearchOptionFamily = atomFamily<string, string>({
  key: 'tableFamily/SearchOption',
  default: '',
});

export const tCoinOptionFamily = atomFamily<string, string>({
  key: 'tableFamily/CoinOption',
  default: 'ALL',
});

export enum Tables {
  Import = 'import',
  Export = 'export',
}

export const currentTableInfoFamily = selectorFamily({
  key: 'tableFamily/tableInfo',
  get:
    (id: string) =>
    ({ get }) => {
      const tStartDate = get(tStartDateFamily(id));
      const tEndDate = get(tEndDateFamily(id));
      const tKeyword = get(tKeywordFamily(id));
      const tStatus = get(tStatusFamily(id));
      const tCoinOption = get(tCoinOptionFamily(id));
      const tSearchOption = get(tSearchOptionFamily(id));
      return { tStartDate, tEndDate, tKeyword, tStatus, tSearchOption, tCoinOption };
    },
  set:
    (id: string) =>
    ({ set }, params) => {
      if (!(params instanceof DefaultValue)) {
        set(tStartDateFamily(id), params.tStartDate);
        set(tEndDateFamily(id), params.tEndDate);
        set(tKeywordFamily(id), params.tKeyword);
        set(tStatusFamily(id), params.tStatus);
        set(tCoinOptionFamily(id), params.tCoinOption);
        set(tSearchOptionFamily(id), params.tSearchOption);
      }
    },
});
