import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from '@sections/Sidebar';
import Header from '@sections/Header';

const Layout = () => {
  return (
    // <Flex minH="100vh">
    //   <Sidebar />
    //   <Flex direction="column" flex={1} overflowY="scroll" h={'100vh'} bg="blackAlpha.50">
    //     <Header />
    //     <Outlet />
    //   </Flex>
    // </Flex>

    <Flex direction="column" flex={1} overflowY="scroll" h={'100vh'} mb={10}>
      <Header />
      <Flex direction="row">
        <Sidebar />
        <Box px={8} py={4} minW="900px" flex={1}>
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
