import { Th, Thead, Tr } from '@chakra-ui/react';
import { TableHeaderInfo } from '@const/table';
import styled from '@emotion/styled';
import React from 'react';

interface Props {
  headerInfos: TableHeaderInfo[];
  onAllCheck: (e: { target: { checked: boolean } }) => void;
  isAllChecked: boolean;
}

const NoticeTableHeader: React.FC<Props> = ({ headerInfos, onAllCheck, isAllChecked }) => {
  return (
    <Thead>
      <Tr>
        {headerInfos.map((info) => {
          if (info.key === 'isChecked') {
            return (
              <Th textAlign={'center'} key={info.key}>
                <CheckBoxContainer>
                  <input type="checkbox" onChange={onAllCheck} checked={isAllChecked} />
                </CheckBoxContainer>
              </Th>
            );
          }
          return (
            <Th textAlign={'center'} key={info.key}>
              {info.label}
            </Th>
          );
        })}
      </Tr>
    </Thead>
  );
};

export default NoticeTableHeader;

const CheckBoxContainer = styled.div`
  accent-color: teal;
`;
