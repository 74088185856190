import { Paths } from '@pages/Router';
import { getAllowedUrls } from '@utils/route';
import { getToken } from '@utils/token';
import { useLocation } from 'react-router-dom';
import { atom, selector, useRecoilValue } from 'recoil';

export interface PageInfo {
  id: string;
  parent_id: number;
  url: string;
  name: string;
  depth: number;
  order_by_no: number;
  is_folding_menu: boolean;
}

export interface AdminPages extends PageInfo {
  parent: PageInfo;
  child?: PageInfo[];
}

export interface UserResponse {
  id: string;
  email: string;
  adminPages: AdminPages[];
}

export const userInfoState = atom<UserResponse>({
  key: 'userState/userInfo',
  default: {
    id: '',
    email: '',
    adminPages: [],
  },
});

export const PathsSelector = selector({
  key: 'userState/paths',
  get: ({ get }) => {
    const userInfo = get(userInfoState);
    const allowedUrls = getAllowedUrls(
      Paths,
      userInfo.adminPages.map((el) => el.url),
    );

    // 메뉴가 될 인포 정렬
    const parentPaths = userInfo.adminPages
      .filter((el) => el.depth === 1)
      .sort((a, b) => a.order_by_no - b.order_by_no);

    // 서브메뉴가 될 인포 필터링
    const childPaths = userInfo.adminPages.filter((el) => el.depth === 2);

    //  서브메뉴들의 부모아이디 별 구분
    const pathByParentId: { [key in string]: PageInfo[] } = {};
    childPaths.forEach((pathInfo) => {
      if (!pathByParentId[pathInfo.parent_id]) {
        pathByParentId[pathInfo.parent_id] = [pathInfo];
      } else {
        pathByParentId[pathInfo.parent_id].push(pathInfo);
      }
    });

    // 서브메뉴들 사이의 순서 정렬
    for (const parentId in pathByParentId) {
      pathByParentId[parentId] = pathByParentId[parentId].sort(
        (a, b) => a.order_by_no - b.order_by_no,
      );
    }

    // 메뉴에 서브메뉴정보 추가
    const paths = parentPaths.map((el) => {
      if (Object.keys(pathByParentId).includes(el.id)) {
        return { ...el, child: pathByParentId[el.id] };
      } else {
        return el;
      }
    });

    return { allowedUrls, paths };
  },
});

export const isLoggedInSelector = selector({
  key: 'userState/isLoggedIn',
  get: ({ get }) => {
    const userInfo = get(userInfoState);
    const accessToken = getToken();

    if (userInfo.email && accessToken) {
      return true;
    } else {
      return false;
    }
  },
});

//현재 접속한 페이지 Id, 아직 접속 전일 때 loading 컴포넌트 렌더링
export function useCurrentPageId() {
  const { pathname } = useLocation();
  const { adminPages } = useRecoilValue(userInfoState);

  const currentPageInfo = adminPages.filter((el) => el.url === pathname)[0];
  const pageId = Number(currentPageInfo?.id) || 0;

  return { pageId };
}
