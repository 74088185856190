import Pagination from '@articles/Pagination';
import { TableWrapper } from '@atoms/table';
import { Box, Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { LanguageOptionList } from '@const/language';
import { NoticeTableHeaderList } from '@const/notice';
import styled from '@emotion/styled';
import { makeNoticeVariables } from '@graphql/notice';
import { Paths } from '@pages/Router';
import { currentPageFamily, perPageFamily } from '@store/family/tableFamily';
import {
  keywordState,
  noticeListInfoState,
  selectedLanguageOptionState,
  selectedSearchOptionState,
  selectedStatusOptionState,
  useSearchNotices,
} from '@store/noticeState';
import { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import NoResult from '../../../components/atoms/NoResult';
import NoticeTableHeader from './NoticeTableHeader';
import PerpageSelectNotice from './PerpageSelectNotice';
import SelectedButtonGroup from './SelectedButtonGroup';

function NoticeTable() {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useRecoilState(perPageFamily('notice'));
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily('notice'));
  const selectedSearchOption = useRecoilValue(selectedSearchOptionState);
  const selectedLanguageOption = useRecoilValue(selectedLanguageOptionState);
  const selectedStatusOption = useRecoilValue(selectedStatusOptionState);

  const noticeListInfo = useRecoilValue(noticeListInfoState);
  const keyword = useRecoilValue(keywordState);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [totalSelectedIds, setTotalSelectedIds] = useState<string[]>([]);
  const [selectedIdsByPage, setSelectedIdsByPage] = useState<{ [page: number]: string[] }>({});

  const { searchNotices, loading, error } = useSearchNotices();

  const handleCurrentPage = (value: number) => {
    setSelectedIdsByPage((prev) => {
      return {
        ...prev,
        [currentPage]: selectedIds,
      };
    });
    setCurrentPage(value);
    const newSelectedIds = selectedIdsByPage[value] || [];
    setSelectedIds(newSelectedIds);
  };

  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  const handleSelectedIdsChange = (value: string[]) => {
    setSelectedIds(value);
  };

  const handleTotalSelectedIdsChange = (value: string[]) => {
    setTotalSelectedIds(value);
  };

  const onSingleCheckChange = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
      setTotalSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds(selectedIds.filter((el) => el !== id));
      setTotalSelectedIds(totalSelectedIds.filter((el) => el !== id));
    }
  };

  const onAllCheckChange = (checked: boolean) => {
    const idArray: SetStateAction<string[]> = [];
    noticeListInfo.data.forEach((el) => idArray.push(el.id));

    if (checked) {
      setSelectedIds(idArray);
      setTotalSelectedIds((prev) => [...prev, ...idArray]);
    } else {
      setSelectedIds([]);
      setTotalSelectedIds((prev) => prev.filter((id) => !idArray.includes(id)));
    }
  };

  useEffect(() => {
    searchNotices({
      variables: makeNoticeVariables({
        listLocale: selectedLanguageOption,
        isActive: selectedStatusOption,
        currentPage,
        perPage,
        selectedSearchOption,
        keyword,
      }),
    });

    if (selectedIdsByPage[currentPage]) {
      setSelectedIds(selectedIdsByPage[currentPage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, currentPage]);

  if (error) {
    return <div></div>;
  }

  return (
    <Box>
      <Flex flexDirection={'column'}>
        <PerpageSelectNotice onChange={handlePerPage} />
      </Flex>

      <TableWrapper>
        <Table size="md">
          <NoticeTableHeader
            headerInfos={NoticeTableHeaderList}
            onAllCheck={(e: { target: { checked: boolean } }) => onAllCheckChange(e.target.checked)}
            isAllChecked={selectedIds.length === perPage}
          />
          <Tbody fontSize="sm">
            {noticeListInfo.data.map(
              ({ created_at, id, subject, is_active, locale, is_fixed }, idx) => {
                return (
                  <Tr key={id}>
                    <Td textAlign={'center'}>
                      <CheckBoxContainer>
                        <input
                          type="checkbox"
                          onChange={(e) => onSingleCheckChange(e.target.checked, id)}
                          checked={totalSelectedIds.includes(id)}
                        />
                      </CheckBoxContainer>
                    </Td>
                    <Td textAlign={'center'}>
                      {currentPage === 1
                        ? noticeListInfo.paginatorInfo.total - idx
                        : noticeListInfo.paginatorInfo.total - (idx + perPage * (currentPage - 1))}
                    </Td>
                    <Td
                      maxWidth="200px"
                      fontWeight={is_fixed ? 'bold' : ''}
                      onClick={() => navigate(`${Paths.Notice.replace(':id', id)}`)}
                    >
                      <SubjectContainer>{subject}</SubjectContainer>
                    </Td>
                    <Td textAlign={'center'}>{is_active ? '노출' : '숨김'}</Td>
                    <Td textAlign={'center'}>
                      {
                        LanguageOptionList.filter((languageOption) => {
                          return languageOption.key === locale;
                        })[0].value
                      }
                    </Td>
                    <Td textAlign={'center'}>{String(created_at)}</Td>
                  </Tr>
                );
              },
            )}
          </Tbody>
        </Table>
        {!loading && noticeListInfo.data.length === 0 && <NoResult />}
      </TableWrapper>
      <Pagination
        perPage={perPage}
        perList={10}
        totalLength={noticeListInfo.paginatorInfo.total}
        currentPage={currentPage}
        onPageChange={handleCurrentPage}
      />
      <SelectedButtonGroup
        selectedIds={selectedIds}
        onSelectedIdsChange={handleSelectedIdsChange}
        totalSelectedIds={totalSelectedIds}
        onTotalSelectedIdsChange={handleTotalSelectedIdsChange}
      />
    </Box>
  );
}

export default NoticeTable;

const SubjectContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
`;

const CheckBoxContainer = styled.div`
  accent-color: teal;
`;
