import { atom } from 'recoil';

// export type ModalProps = {
//   idx: string;
//   type: string;
//   component?: () => ReactJSXElement;
// };

export const modalState = atom<any[]>({
  key: 'modalState',
  default: [],
});
