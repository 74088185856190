import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ContentWrapper, BgWrapper } from '@layout/index';
import Title from '@atoms/Title';
import ImportsFilter from './components/ImportsFilter';
import ImportsTable from './components/ImportsTable';
import ImportsSummary from './components/ImportsSummary';
import { useCurrentPageId } from '@store/userState';

const ImportList = () => {
  const { pageId } = useCurrentPageId();
  return (
    <Flex flexDir="column">
      <Title title="티모포인트 충전 내역" />
      <ContentWrapper>
        <BgWrapper mb={10}>
          <ImportsSummary />
        </BgWrapper>

        <BgWrapper>
          <ImportsFilter pageId={pageId} />
          <ImportsTable pageId={pageId} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default ImportList;
