import { FaqType, NoticeType } from '@/types/index';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { isListButtonClickedFamily } from '@store/family/navigationFamily';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

interface Props {
  listPath: string;
  navigatePath: string;
  data: FaqType | NoticeType;
}

const NavigationButtons: React.FC<Props> = ({ listPath, navigatePath, data }) => {
  const navigate = useNavigate();
  const setIsListButtonClicked = useSetRecoilState(isListButtonClickedFamily(listPath));

  const onListClick = () => {
    navigate(listPath);
    setIsListButtonClicked(true);
  };

  const onPreviousButtonClick = () => {
    if (data && data.previous) {
      navigate(`${navigatePath.replace(':id', data.previous.id)}`);
    } else {
      alert('이전 글이 없습니다.');
    }
  };

  const onNextButtonClick = () => {
    if (data && data.next) {
      navigate(`${navigatePath.replace(':id', data.next.id)}`);
    } else {
      alert('이전 글이 없습니다.');
    }
  };
  return (
    <ButtonGroup colorScheme="teal" variant="outline">
      <Button onClick={onListClick}>목록</Button>
      <Button onClick={onPreviousButtonClick}>이전 글</Button>
      <Button onClick={onNextButtonClick}>다음 글</Button>
    </ButtonGroup>
  );
};

export default NavigationButtons;
