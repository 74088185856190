import { LanguageOption } from '@/types/index';
import { Box, Select } from '@chakra-ui/react';
import { LanguageOptionList } from '@const/language';
import React from 'react';

interface SelectLanguageProps {
  onChange: (value: LanguageOption) => void;
  defaultLanguage: string;
}

const SelectLanguage: React.FC<SelectLanguageProps> = ({ onChange, defaultLanguage }) => {
  return (
    <Box>
      <Select onChange={() => onChange} defaultValue={defaultLanguage}>
        {LanguageOptionList.map((lang) => {
          return (
            <option key={lang.key} value={lang.key}>
              {lang.value}
            </option>
          );
        })}
      </Select>
    </Box>
  );
};

export default SelectLanguage;
