import { LanguageOption } from '@/types/index';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { LanguageOptionList } from '@const/language';
import React from 'react';
interface Props {
  defaultLanguage: LanguageOption;
  onChange: (string: LanguageOption) => void;
}

const LanguageTab: React.FC<Props> = ({ defaultLanguage, onChange }) => {
  const getIndexByKey = (keyToFind: string) => {
    return LanguageOptionList.findIndex((item) => item.key === keyToFind);
  };

  return (
    <Tabs
      size="md"
      variant="enclosed"
      colorScheme="teal"
      defaultIndex={getIndexByKey(defaultLanguage)}
      onChange={(index) => {
        onChange(LanguageOptionList[index].key);
      }}
    >
      <TabList>
        {LanguageOptionList.map((lang, idx) => {
          return <Tab key={idx}>{lang.value}</Tab>;
        })}
      </TabList>
    </Tabs>
  );
};

export default LanguageTab;
