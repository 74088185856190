import { useMutation } from '@apollo/client';
import { UPLOAD } from '@graphql/common';
import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import ReactQuill, { Quill as RQuill } from 'react-quill';
import { SetterOrUpdater } from 'recoil';

interface QuillEditorProps {
  onChange: (value: string) => void;
  onImageUpload: Dispatch<SetStateAction<number[]>>;
  placeholder?: string;
  height?: string;
  defaultContent?: string;
}

const QuillEditor: React.FC<QuillEditorProps> = ({
  onChange,
  onImageUpload,
  placeholder,
  height,
  defaultContent,
}) => {
  const quillRef = useRef<ReactQuill>(null);

  let editor: Quill;
  RQuill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
  RQuill.debug('error');

  const [uploadImage] = useMutation(UPLOAD);

  const uploadImageRequest = async (file: File, type: string) => {
    try {
      const result = await uploadImage({
        variables: {
          file: file,
        },
      });

      if (result.data && result.data.upload) {
        onImageUpload((prev) => [...prev, Number(result.data.upload.id)]);

        const img_url = result.data.upload.url;
        if (quillRef.current) {
          editor = quillRef.current.getEditor();
        }
        const range = editor.getSelection();
        if (range) {
          editor.insertEmbed(range.index, 'image', img_url);
        }
      }
    } catch (error) {
      return;
    }
  };

  const handleImageUpload = () => {
    let fileForUpload: File;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      if (input.files) {
        fileForUpload = input.files[0];
      }
      uploadImageRequest(fileForUpload, 'upload');
    });
  };

  const handleImageDrop = async (
    imageDataUrl: any,
    type: any,
    imageData: { toBlob: () => any; toFile: () => any },
  ) => {
    const fileForDrop = imageData.toFile();
    uploadImageRequest(fileForDrop, 'drop');
  };

  const toolbarOptions = [
    ['link', 'image', 'video'],
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ];

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: handleImageUpload,
        },
      },
      imageDropAndPaste: {
        handler: handleImageDrop,
      },
    };
  }, []);

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      placeholder={placeholder || '내용을 입력하세요.'}
      modules={modules}
      style={{ height: height || '300px' }}
      onChange={onChange}
      defaultValue={defaultContent}
    />
  );
};

export default QuillEditor;
