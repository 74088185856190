import { useMutation } from '@apollo/client';
import Title from '@atoms/Title';
import { Box, Button, Stack } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { UPDATE_NOTICE } from '@graphql/notice';
import useModal from '@hooks/useModal';
import useResetNotice from '@hooks/useResetNotice';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { deleteImagesState, noticeState, noticeUpdateInputState } from '@store/noticeState';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import NoticeWriter from './NoticeWriter';

function UpdateNotice() {
  const navigator = useNavigate();

  const noticeUpdateInput = useRecoilValue(noticeUpdateInputState);
  const notice = useRecoilValue(noticeState);
  const [deleteImages, setDeleteImages] = useRecoilState(deleteImagesState);

  const resetNoticeState = useResetNotice();
  const { openModal } = useModal();

  const [updateNotice, { error }] = useMutation(UPDATE_NOTICE, {
    onCompleted: ({ result }) => {
      alert('수정되었습니다.');
      resetNoticeState();
      navigator(`${Paths.Notice.replace(':id', noticeUpdateInput.id)}`);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '공지사항 수정을 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          resetNoticeState();
          navigator(Paths.NoticeBoard);
        },
      });
    },
  });

  const checkDeleteImages = () => {
    for (const image of notice.images) {
      if (!notice.content.includes(image.url)) {
        setDeleteImages((prev) => [...prev, Number(image.id)]);
      }
    }
  };

  const handleSubmitClick = () => {
    if (noticeUpdateInput.subject.length === 0 || noticeUpdateInput.content.length === 0) {
      alert('제목 및 내용을 입력하세요.');
      return;
    }
    checkDeleteImages();
    updateNotice({
      variables: {
        input: noticeUpdateInput,
      },
    });
  };

  const onSubmitCancelClick = () => {
    openModal({
      type: MODAL_TYPES.alert,
      title: '취소',
      message: '수정을 취소하였습니다.',
      alert: () => {
        navigator(Paths.NoticeBoard);
        resetNoticeState();
      },
    });
  };

  const onSubmitClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '저장',
      message: '공지사항을 수정하시겠습니까?',
      confirm: () => {
        handleSubmitClick();
      },
    });
  };

  useEffect(() => {
    if (deleteImages.length > 0) {
      updateNotice({
        variables: {
          input: noticeUpdateInput,
        },
      });
    }
  }, [deleteImages]);

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Box px={8} minW="900px">
      <Title title="공지사항 수정" />

      <ContentWrapper>
        <BgWrapper style={{ paddingBottom: '4rem' }}>
          <NoticeWriter />
        </BgWrapper>
      </ContentWrapper>

      <Stack direction="row" spacing={4} justifyContent="end" mb={5}>
        <Button colorScheme="teal" onClick={onSubmitCancelClick}>
          취소
        </Button>
        <Button colorScheme="teal" onClick={onSubmitClick}>
          저장
        </Button>
      </Stack>
    </Box>
  );
}

export default UpdateNotice;
