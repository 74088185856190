import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ContentWrapper, BgWrapper } from '@layout/index';
import Title from '@atoms/Title';
import ExportsFilter from './components/ExportsFilter';
import ExportsTable from './components/ExportsTable';
import ExportsSummary from './components/ExportsSummary';
import { useCurrentPageId } from '@store/userState';

const ExportList = () => {
  const { pageId } = useCurrentPageId();
  return (
    <Flex flexDir="column">
      <Title title="티모포인트 사용 내역" />
      <ContentWrapper>
        <BgWrapper mb={10}>
          <ExportsSummary />
        </BgWrapper>
        <BgWrapper>
          <ExportsFilter pageId={pageId} />
          <ExportsTable pageId={pageId} />
        </BgWrapper>
      </ContentWrapper>
    </Flex>
  );
};

export default ExportList;
