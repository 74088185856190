import { Select } from '@chakra-ui/react';
import { perPageFamily } from '@store/family/tableFamily';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  onChange: (value: number) => void;
}

const PerpageSelectNotice: React.FC<Props> = ({ onChange }) => {
  const perPage = useRecoilValue(perPageFamily('notice'));

  return (
    <Select
      value={perPage}
      maxW={36}
      size="sm"
      onChange={(e) => {
        onChange(Number(e.target.value));
      }}
    >
      <option value={10}>10줄씩 보기</option>
      <option value={20}>20줄씩 보기</option>
      <option value={50}>50줄씩 보기</option>
      <option value={100}>100줄씩 보기</option>
    </Select>
  );
};

export default PerpageSelectNotice;
