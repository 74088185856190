import { atom, useRecoilState } from 'recoil';
import { ImportsData, ImportsSummaryType, PaginatorInfoType, initialPageInfo } from '@/types/index';
import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client';
import { IMPORT_LIST } from '@graphql/transaction';

export const dataListState = atom<ImportsData[]>({
  key: 'importListState/dataListState',
  default: [],
});

export const paginatorInfoState = atom<PaginatorInfoType>({
  key: 'importListState/paginatorInfoState',
  default: initialPageInfo,
});

export const summaryInfoState = atom<ImportsSummaryType>({
  key: 'importListState/summaryInfo',
  default: {
    total_count: 0,
    completed_count: 0,
    cancel_count: 0,
    export_amount: 0,
    import_amount: 0,
    fee: 0,
  },
});

export function useImportsData(): {
  summaryInfo: ImportsSummaryType;
  getImportList: LazyQueryExecFunction<any, OperationVariables>;
  dataList: ImportsData[];
  paginatorInfo: PaginatorInfoType;
} {
  const [paginatorInfo, setPaginatorInfo] = useRecoilState(paginatorInfoState);
  const [dataList, setDataList] = useRecoilState(dataListState);
  const [summaryInfo, setSummaryInfo] = useRecoilState(summaryInfoState);

  const [getImportList] = useLazyQuery(IMPORT_LIST, {
    onCompleted: ({ adminImportSwaps, adminImportSwapAmount }) => {
      setSummaryInfo(adminImportSwapAmount);
      setPaginatorInfo(adminImportSwaps.paginatorInfo);
      setDataList(adminImportSwaps.data);
    },
  });

  return { summaryInfo, getImportList, dataList, paginatorInfo };
}
