import { ExportsData, ImportsData } from '../types';

export const parseSwapStatus = (status: string): string => {
  switch (status) {
    case 'COMPLETED':
      return '완료';
    case 'CANCEL':
      return '취소';
    case 'PENDING':
      return '대기';
    default:
      return status;
  }
};

export const parseDownloadImportsData = (list: ImportsData[]) => {
  return list.map((el: ImportsData, index: number) => {
    return {
      number: list.length - index,
      from: el.acronym,
      direction: '->',
      to: 'TMOP',
      email: el.email,
      partnerId: el.partner_id,
      orderNo: el.order_no,
      partnerOrderNo: el.partner_order_no,
      originalAmount: el.original_amount,
      fee: el.fee,
      importPoint: el.amount,
      status: el.swap_status,
      completedAt: el.created_at,
      canceledAt: el.canceled_at,
    };
  });
};

export const parseDownloadExportsData = (list: ExportsData[]) => {
  return list.map((el: ExportsData, index: number) => {
    return {
      number: list.length - index,
      from: 'TMOP',
      direction: '->',
      to: el.acronym,
      email: el.email,
      partnerId: el.partner_id,
      orderNo: el.order_no,
      partnerOrderNo: el.partner_order_no,
      originalAmount: el.original_amount,
      fee: el.fee,
      importPoint: el.amount,
      reward: el.reward,
      status: el.swap_status,
      completedAt: el.created_at,
      canceledAt: el.canceled_at,
    };
  });
};
