import { Select } from '@chakra-ui/react';
import React from 'react';

interface Props {
  handleChange: (value: number) => void;
}

const PerpageSelect: React.FC<Props> = ({ handleChange }) => {
  return (
    <Select
      maxW={36}
      size="sm"
      onChange={(e) => {
        handleChange(Number(e.target.value));
      }}
    >
      <option value={10}>10줄씩 보기</option>
      <option value={20}>20줄씩 보기</option>
      <option value={50}>50줄씩 보기</option>
      <option value={100}>100줄씩 보기</option>
    </Select>
  );
};

export default PerpageSelect;
