import { onError } from '@apollo/client/link/error';
import { removeLoginInfo } from '@utils/index';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.info(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );

      // if (message === 'This action is unauthorized.') {
      //   removeLoginInfo();
      //   window.location.reload();
      // }
    });
  }

  if (networkError) console.info(`[Network error]: ${networkError}`);
});

export default errorLink;
