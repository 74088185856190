import { TableHeaderInfo } from './table';

export const NoticeTableHeaderList: TableHeaderInfo[] = [
  { label: '', key: 'isChecked' },
  { label: '번호', key: 'number' },
  { label: '제목', key: 'subject' },
  { label: '게시글 상태', key: 'status' },
  { label: '언어', key: 'locale' },
  { label: '작성일', key: 'createdAt' },
];
