export interface TableHeaderInfo {
  label: string;
  key: string;
}

export const importListDownloadHeader: TableHeaderInfo[] = [
  { label: '번호', key: 'number' },
  { label: 'From', key: 'from' },
  { label: '->', key: 'direction' },
  { label: 'To', key: 'to' },
  { label: '티모 월렛 ID', key: 'email' },
  { label: '제휴사 ID', key: 'partnerId' },
  { label: '거래번호', key: 'orderNo' },
  { label: '제휴사 거래번호', key: 'partnerOrderNo' },
  { label: '신청수량', key: 'originalAmount' },
  { label: '수수료', key: 'fee' },
  { label: '스왑수량', key: 'importPoint' },
  { label: '상태', key: 'status' },
  { label: '완료 일시', key: 'completedAt' },
  { label: '취소 일시', key: 'canceledAt' },
];

export const exportListDownloadHeader: TableHeaderInfo[] = [
  { label: '번호', key: 'number' },
  { label: 'From', key: 'from' },
  { label: '->', key: 'direction' },
  { label: 'To', key: 'to' },
  { label: '티모 월렛 ID', key: 'email' },
  { label: '제휴사 ID', key: 'partnerId' },
  { label: '거래번호', key: 'orderNo' },
  { label: '제휴사 거래번호', key: 'partnerOrderNo' },
  { label: '사용 티모포인트', key: 'originalAmount' },
  { label: '수수료', key: 'fee' },
  { label: '지급 제휴사 포인트', key: 'importPoint' },
  { label: '리워드', key: 'reward' },
  { label: '상태', key: 'status' },
  { label: '완료 일시', key: 'completedAt' },
  { label: '취소 일시', key: 'canceledAt' },
];
