import { NoticeFilter } from '@/types/index';
import QuillEditor from '@articles/QuillEditor';
import SelectLanguage from '@articles/SelectLanguage';
import { Box, Checkbox, Input, InputGroup, InputLeftAddon, Select } from '@chakra-ui/react';
import useResetNotice from '@hooks/useResetNotice';
import ThumbnailInput from '@pages/noticeBoard/components/ThumbnailInput';
import {
  contentState,
  isActiveState,
  isFixedState,
  localeState,
  noticeTypeState,
  subjectState,
  uploadedImageState,
} from '@store/noticeState';
import React, { SetStateAction, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

function NoticeWriter() {
  const [subject, setSubject] = useRecoilState(subjectState);
  const [content, setContent] = useRecoilState(contentState);
  const [locale, setLocale] = useRecoilState(localeState);
  const [isActive, setIsActive] = useRecoilState(isActiveState);
  const [typeFilter, setTypeFilter] = useRecoilState(noticeTypeState);
  const [isFixed, setIsFixed] = useRecoilState(isFixedState);
  const setUploadedImageState = useSetRecoilState(uploadedImageState);
  const resetNoticeState = useResetNotice();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const handleIsFixedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFixed(Number(e.target.checked));
  };

  const handleIsActiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'INACTIVE') {
      setIsActive(0);
    } else if (e.target.value === 'ACTIVE') {
      setIsActive(1);
    }
  };
  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeFilter(e.target.value as NoticeFilter);
  };

  const handleOnContentChange = (value: string) => {
    setContent(value);
  };

  const handleSetUploadedImages = (value: SetStateAction<number[]>) => {
    setUploadedImageState(value);
  };

  useEffect(() => {
    window.addEventListener('popstate', () => resetNoticeState());
    return () => {
      window.removeEventListener('popstate', () => resetNoticeState());
    };
  });

  return (
    <Box>
      <Box display="flex" justifyContent={'space-between'} gap={12} minW={96}>
        <InputGroup mb={2} flex={1}>
          <InputLeftAddon children="제목" />
          <Input
            type="text"
            placeholder="제목을 입력하세요."
            defaultValue={subject || undefined}
            focusBorderColor="teal.500"
            onChange={handleTitleChange}
          />
        </InputGroup>
        <Box display="flex" justifyContent={'space-around'} gap={4}>
          <Checkbox
            colorScheme="teal"
            size="md"
            defaultChecked={Boolean(isFixed)}
            onChange={handleIsFixedChange}
          >
            중요 공지
          </Checkbox>
          <SelectLanguage onChange={setLocale} defaultLanguage={locale || 'KOREAN'} />
          <Select
            w={'fit-content'}
            defaultValue={isActive === 1 ? 'ACTIVE' : 'INACTIVE'}
            onChange={handleIsActiveChange}
          >
            <option value="ACTIVE">노출</option>
            <option value="INACTIVE">숨김</option>
          </Select>
          <Select w={'fit-content'} defaultValue={typeFilter} onChange={handleTypeChange}>
            <option value={NoticeFilter.NOTICE}>공지사항</option>
            <option value={NoticeFilter.EVENT}>이벤트</option>
          </Select>
        </Box>
      </Box>

      <QuillEditor
        onChange={handleOnContentChange}
        onImageUpload={handleSetUploadedImages}
        defaultContent={content || undefined}
      />
      <ThumbnailInput />
    </Box>
  );
}

export default NoticeWriter;
