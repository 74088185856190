import FullLayout from '@components/layout/FullLayout';
import Layout from '@components/layout/Layout';
import { Routes as ReactRoutes, Route, useLocation, useParams } from 'react-router-dom';

import DashBoard from './dashBoard';
import ExportList from './exportList';
import FaqBoard from './faqBoard';
import CreateFaq from './faqBoard/components/CreateFaq';
import Faq from './faqBoard/components/Faq';
import UpdateFaq from './faqBoard/components/UpdateFaq';
import ImportList from './importList';
import Login from './login';
import NoticeBoard from './noticeBoard';
import CreateNotice from './noticeBoard/components/CreateNotice';
import Notice from './noticeBoard/components/Notice';
import UpdateNotice from './noticeBoard/components/UpdateNotice';
import { useRecoilValue } from 'recoil';
import { PathsSelector } from '@store/userState';
import { useLayoutEffect } from 'react';
import { replaceNumberToStringId } from '@utils/route';

export enum Paths {
  Dashboard = '/',
  Login = '/login',

  // 스왑내역 탭
  ImportList = '/order/import',
  ExportList = '/order/export',

  // 고객센터 탭
  NoticeBoard = '/support/notice',
  CreateNotice = '/support/notice/create',
  Notice = '/support/notice/:id',
  UpdateNotice = '/support/notice/update/:id',

  FaqBoard = '/support/faq',
  CreateFaq = '/support/faq/create',
  Faq = '/support/faq/:id',
  UpdateFaq = '/support/faq/update/:id',
}

const Router = () => {
  const id = useParams();
  const { allowedUrls } = useRecoilValue(PathsSelector);
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // 로그인 페이지에서는 접근권한 체크 생략
    if (pathname === Paths.Login) {
      return;
    }

    if (allowedUrls.length > 0 && !allowedUrls.includes(replaceNumberToStringId(pathname))) {
      alert('접근 권한이 없습니다.');
      window.location.href = '/';
    }
  }, [pathname, allowedUrls, id]);

  return (
    <>
      <ReactRoutes>
        <Route element={<Layout />}>
          <Route path={Paths.Dashboard} element={<DashBoard />} />

          {/* 스왑내역 탭 */}
          <Route path={Paths.ImportList} element={<ImportList />} />
          <Route path={Paths.ExportList} element={<ExportList />} />

          {/* 고객센터 탭 */}
          <Route path={Paths.NoticeBoard} element={<NoticeBoard />} />
          <Route path={Paths.CreateNotice} element={<CreateNotice />} />
          <Route path={Paths.Notice} element={<Notice />} />
          <Route path={Paths.UpdateNotice} element={<UpdateNotice />} />

          <Route path={Paths.FaqBoard} element={<FaqBoard />} />
          <Route path={Paths.CreateFaq} element={<CreateFaq />} />
          <Route path={Paths.Faq} element={<Faq />} />
          <Route path={Paths.UpdateFaq} element={<UpdateFaq />} />
        </Route>
        <Route element={<FullLayout />}>
          <Route path={Paths.Login} element={<Login />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
      </ReactRoutes>
    </>
  );
};

export default Router;
