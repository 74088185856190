import { gql } from '@apollo/client';
import { LanguageOption, SearchOption } from '../types';

const FAQ_LIST = gql`
  query ($locale: LocaleStatus, $page: Int, $perPage: Int) {
    faqs(locale: $locale, page: $page, first: $perPage) {
      data {
        id
        subject
        order_by_no
        locale
      }
      paginatorInfo {
        count
        currentPage
        perPage
        total
        lastPage
      }
    }
  }
`;

const GET_FAQ = gql`
  query ($id: ID!) {
    faq(id: $id) {
      id
      subject
      content
      order_by_no
      previous {
        id
      }
      next {
        id
      }
      created_at
      updated_at
      images {
        id
        url
      }
      locale
    }
  }
`;

const CREATE_FAQ = gql`
  mutation ($input: CreateFaqInput!) {
    createFaq(input: $input) {
      id
      subject
      content
      order_by_no
      updated_at
      images {
        id
        url
      }
      locale
    }
  }
`;

const UPDATE_FAQ = gql`
  mutation ($input: UpdateFaqInput!) {
    updateFaq(input: $input) {
      id
      subject
      content
      order_by_no
      updated_at
      images {
        id
        url
      }
      locale
    }
  }
`;

const DELETE_FAQ = gql`
  mutation ($id: ID!) {
    deleteFaq(id: $id) {
      id
      subject
    }
  }
`;

const SEARCH_FAQS = gql`
  query (
    $locale: LocaleStatus!
    $page: Int
    $where: QueryFaqSearchWhereWhereConditions
    $perPage: Int
  ) {
    faqSearch(
      locale: $locale
      where: $where
      orderBy: [{ column: ORDER_BY_NO, order: ASC }]
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        perPage
        total
        lastPage
      }
      data {
        id
        locale
        subject
        created_at
      }
    }
  }
`;

const CHANGE_OR_BY_NO_FAQ = gql`
  mutation ($input: ChangeOrderByNoFaqInput!) {
    changeOrderByNoFaq(input: $input) {
      status
      message
    }
  }
`;

interface faqParams {
  listLocale: LanguageOption;
  currentPage: number;
  selectedSearchOption: SearchOption;
  keyword: string;
  perPage: number;
}

type WhereType =
  | { column: string; operator: string; value: string }
  | { OR: { column: string; operator: string; value: string }[] };

export const constructWhere = (selectedSearchOption: string, keyword: string) => {
  let whereObject: WhereType | undefined = undefined;

  if (selectedSearchOption === 'SUBJECT') {
    whereObject = { column: 'SUBJECT', operator: 'LIKE', value: `%${keyword}%` };
  } else if (selectedSearchOption === 'CONTENT') {
    whereObject = { column: 'CONTENT', operator: 'LIKE', value: `%${keyword}%` };
  } else if (selectedSearchOption === 'OR') {
    whereObject = {
      OR: [
        { column: 'SUBJECT', operator: 'LIKE', value: `%${keyword}%` },
        { column: 'CONTENT', operator: 'LIKE', value: `%${keyword}%` },
      ],
    };
  }

  return whereObject;
};

export const makeFaqVariables = ({
  listLocale,
  currentPage,
  selectedSearchOption,
  keyword,
  perPage,
}: faqParams) => {
  const locale = listLocale === 'ALL' ? null : listLocale;
  const where = constructWhere(selectedSearchOption, keyword);

  let variables;

  if (!locale) {
    variables = { page: currentPage, where, perPage };
  }

  if (locale) {
    variables = { page: currentPage, where, locale, perPage };
  }

  return variables;
};

export { CHANGE_OR_BY_NO_FAQ, CREATE_FAQ, DELETE_FAQ, FAQ_LIST, GET_FAQ, SEARCH_FAQS, UPDATE_FAQ };
