import { LanguageOption, NoticeStatus, SearchOption, SelectOption, Status } from '../types';

export const statusOptions: SelectOption<Status>[] = [
  { key: 'ALL', value: '전체' },
  { key: 'COMPLETED', value: '완료' },
  { key: 'CANCEL', value: '취소' },
];

export const searchOptions: SelectOption<SearchOption>[] = [
  { key: 'OR', value: '제목 + 내용' },
  { key: 'SUBJECT', value: '제목' },
  { key: 'CONTENT', value: '내용' },
];

export const noticeStatusOptions: SelectOption<NoticeStatus>[] = [
  { key: 'ALL', value: '전체' },
  { key: 'ACTIVE', value: '노출' },
  { key: 'INACTIVE', value: '숨김' },
];

export const languageOptions: SelectOption<LanguageOption>[] = [
  { value: '전체', key: 'ALL' },
  { value: '한국어', key: 'KOREAN' },
  { value: '영어', key: 'ENGLISH' },
];
