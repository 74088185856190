import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import authLink from './authLink';
import errorLink from './errorLink';
import httpLink from './httpLink';

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default client;
