import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_OPTIONS } from '@graphql/options';
import { SelectOption, Status } from '../types';
import { Tables } from '@store/family/tableFamily';
import { useCurrentPageId } from '@store/userState';

interface CoinSearchOptionResponse {
  coin_id: number;
  name: string;
  option: SelectOption<Status>[];
}

type CoinOptionType = {
  [key in Tables]: SelectOption<Tables>[];
};

type SearchOptionType = {
  [key in string]: SelectOption<string>[];
};

// 검색옵션 관련 훅
function useSearchOptions(_table: Tables): {
  searchOptions: SearchOptionType;
  coinOptions: CoinOptionType;
} {
  const { pageId } = useCurrentPageId();
  const [searchOptions, setSearchOptions] = useState<SearchOptionType>({});
  const [coinOptions, setCoinOptions] = useState<CoinOptionType>({
    [Tables.Import]: [],
    [Tables.Export]: [],
  });

  useQuery(SEARCH_OPTIONS, {
    variables: { pageId },
    onCompleted: ({ adminSearchOption }) => {
      const importCoinOption = adminSearchOption.import.map((info: CoinSearchOptionResponse) => {
        if (info.coin_id) {
          return { key: info.coin_id, value: info.name };
        } else {
          return { key: 'ALL', value: info.name };
        }
      });

      const exportCoinOption = adminSearchOption.export.map((info: CoinSearchOptionResponse) => {
        if (info.coin_id) {
          return { key: info.coin_id, value: info.name };
        } else {
          return { key: 'ALL', value: info.name };
        }
      });

      const coinOptions: Record<Tables, SelectOption<Tables>[]> = {
        [Tables.Import]: importCoinOption,
        [Tables.Export]: exportCoinOption,
      };

      const searchOptionsById: SearchOptionType = {};
      adminSearchOption[_table].forEach((info: any) => {
        if (info.coin_id) {
          searchOptionsById[info.coin_id] = info.option;
        } else {
          searchOptionsById['ALL'] = info.option;
        }
      });

      // 하나의 데이터를 coinOptions, searchOption 으로 normalize 이후에 반환
      setCoinOptions(coinOptions);
      setSearchOptions(searchOptionsById);
    },
  });

  return { searchOptions, coinOptions };
}

export default useSearchOptions;
