import TitleSelect from '@atoms/TitleSelect';
import { TableFilterWrapper } from '@atoms/table';
import { Button, Flex, Input } from '@chakra-ui/react';
import { languageOptions, noticeStatusOptions, searchOptions } from '@const/filter';
import { makeNoticeVariables } from '@graphql/notice';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import useResetNotice from '@hooks/useResetNotice';
import { Paths } from '@pages/Router';
import { currentPageFamily, perPageFamily } from '@store/family/tableFamily';
import {
  keywordState,
  selectedLanguageOptionState,
  selectedSearchOptionState,
  selectedStatusOptionState,
  useSearchNotices,
} from '@store/noticeState';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

function NoticeFilter() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily('notice'));
  const perPage = useRecoilValue(perPageFamily('notice'));
  const resetNoticeState = useResetNotice();

  const [selectedSearchOption, setSelectedSearchOption] = useRecoilState(selectedSearchOptionState);
  const [selectedLanguageOption, setSelectedLanguageOption] = useRecoilState(
    selectedLanguageOptionState,
  );
  const [selectedStatusOption, setSelectedStatusOption] = useRecoilState(selectedStatusOptionState);
  const [keyword, setKeyword] = useRecoilState(keywordState);

  const { searchNotices, error } = useSearchNotices();

  const onSearchClick = () => {
    setKeyword(keyword);
    searchNotices({
      variables: makeNoticeVariables({
        listLocale: selectedLanguageOption,
        isActive: selectedStatusOption,
        currentPage,
        perPage,
        selectedSearchOption,
        keyword,
      }),
    });
    setCurrentPage(1);
  };

  const onKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);

  const handleSearchOptions = (value: string) => setSelectedSearchOption(value);
  const handleLanguageOptions = (key: string) => setSelectedLanguageOption(key);
  const handleStatusOptions = (key: string) => setSelectedStatusOption(key);

  const onCreateButtonClick = () => {
    resetNoticeState();
    navigate(Paths.CreateNotice);
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <TableFilterWrapper>
      <Flex gap={3} flexDirection={'column'}>
        <Flex gap={3}>
          <TitleSelect
            title="언어"
            onChange={handleLanguageOptions}
            options={languageOptions}
            selectedValue={selectedLanguageOption}
            w="350px"
          ></TitleSelect>
          <TitleSelect
            title="게시글 상태"
            onChange={handleStatusOptions}
            options={noticeStatusOptions}
            selectedValue={selectedStatusOption}
            w="350px"
          ></TitleSelect>
        </Flex>
        <Flex gap={3}>
          <TitleSelect
            title="검색 조건"
            onChange={handleSearchOptions}
            options={searchOptions}
            selectedValue={selectedSearchOption}
            w="350px"
          ></TitleSelect>

          <Input
            size="md"
            maxW="550px"
            type="text"
            placeholder="내용을 입력하세요"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={onKeyDown}
          />
          <Flex gap={3} justifyContent={'flex-end'} flex={1}>
            <Button variant="solid" colorScheme="teal" onClick={onSearchClick}>
              검색
            </Button>
            <Button variant="outline" colorScheme="teal" onClick={onCreateButtonClick}>
              +등록하기
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </TableFilterWrapper>
  );
}

export default NoticeFilter;
