import Pagination from '@articles/Pagination';
import NoResult from '@atoms/NoResult';
import { Box, Flex } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { CHANGE_OR_BY_NO_FAQ, SEARCH_FAQS, makeFaqVariables } from '@graphql/faq';
import useModal from '@hooks/useModal';
import { Paths } from '@pages/Router';
import { currentPageFamily } from '@store/family/tableFamily';
import {
  keywordState,
  selectedLanguageOptionState,
  selectedSearchOptionState,
  useSearchFaqs,
} from '@store/faqState';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import FaqDndList from './FaqDndList';
import { useMutation } from '@apollo/client';
import SubmitButtonGroup from './SubmitButtonGroup';

interface Props {
  isEditingOrder: boolean;
  onEditOrderClick: () => void;
}

const FaqList: React.FC<Props> = ({ isEditingOrder, onEditOrderClick }) => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { searchFaqs, faqList, paginatorInfo, loading } = useSearchFaqs();

  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily('faq'));
  const selectedSearchOption = useRecoilValue(selectedSearchOptionState);
  const selectedLanguageOption = useRecoilValue(selectedLanguageOptionState);
  const keyword = useRecoilValue(keywordState);

  const initialFaqIdList = faqList.map((faq) => faq.id);
  const [orderedIds, setOrderedIds] = useState<string[]>(initialFaqIdList);

  const [changeOrderByNoFaq] = useMutation(CHANGE_OR_BY_NO_FAQ, {
    onCompleted: ({ status }) => {
      alert('저장되었습니다.');
      navigate(Paths.FaqBoard);
    },
    refetchQueries: [
      {
        query: SEARCH_FAQS,
        variables: makeFaqVariables({
          listLocale: selectedLanguageOption,
          currentPage,
          selectedSearchOption: 'OR',
          keyword: '',
          perPage: 10,
        }),
      },
    ],

    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '순서 변경 실패',
        message: '순서 저장에 실패했습니다. 잠시 후 다시 시도해주세요.',
        error: () => {
          navigate(Paths.FaqBoard);
        },
      });
    },
  });

  const handleOrderedIdsChange = (value: string[]) => {
    setOrderedIds(value);
  };

  const handleCancelClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '순서 저장 취소',
      message: '순서 저장을 취소하시겠습니까? 이전 순서로 돌아갑니다.',
      confirm: () => {
        onEditOrderClick();
        setCurrentPage(1);
        navigate(Paths.FaqBoard);
      },
    });
  };

  const handleSubmitClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '순서 저장',
      message: '변경한 순서를 저장하시겠습니까?',
      confirm: () => {
        onEditOrderClick();
        setCurrentPage(1);
        changeOrderByNoFaq({
          variables: {
            input: {
              ids: orderedIds,
              locale: selectedLanguageOption,
            },
          },
        });
      },
    });
  };

  // 페이지 이동 및 편집모드 진입 시 요청 전송
  useEffect(() => {
    let perPage: number;
    if (isEditingOrder) {
      perPage = paginatorInfo.total;
    } else {
      perPage = 10;
    }
    searchFaqs({
      variables: makeFaqVariables({
        listLocale: selectedLanguageOption,
        currentPage,
        selectedSearchOption,
        keyword,
        perPage,
      }),
    });
  }, [currentPage, isEditingOrder]);

  return (
    <Box>
      <Flex flexDirection={'column'}>
        {!loading && faqList.length === 0 && <NoResult />}
        <FaqDndList
          faqList={faqList}
          onOrderedIdsChange={handleOrderedIdsChange}
          isEditingOrder={isEditingOrder}
        />

        {!isEditingOrder && (
          <Pagination
            perPage={10}
            perList={10}
            totalLength={paginatorInfo.total}
            currentPage={currentPage}
            onPageChange={(value) => setCurrentPage(value)}
          />
        )}
        {isEditingOrder && (
          <SubmitButtonGroup onCancelClick={handleCancelClick} onSubmitClick={handleSubmitClick} />
        )}
      </Flex>
    </Box>
  );
};
export default FaqList;
