import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  title: string;
  subTitle?: string;
  mt?: number;
}
const Title: React.FC<Props> = ({ title, subTitle, mt }) => {
  const text = subTitle ? (
    <>
      <Text fontWeight="thin" mr={2}>
        {title} /
      </Text>
      <Text fontWeight="bold"> {subTitle}</Text>
    </>
  ) : (
    <Text fontWeight="bold">{title}</Text>
  );

  return (
    <Box mt={mt}>
      <Flex
        fontSize="xl"
        pl={3}
        borderLeft="4px solid"
        borderColor="teal.400"
        color="blackAlpha.700"
      >
        {text}
      </Flex>
    </Box>
  );
};

export default Title;
