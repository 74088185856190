import { useMutation, useQuery } from '@apollo/client';
import NavigationButtons from '@articles/NavigationButtons';
import Title from '@atoms/Title';
import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { DELETE_FAQ, GET_FAQ, SEARCH_FAQS, makeFaqVariables } from '@graphql/faq';
import useModal from '@hooks/useModal';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { currentPageFamily } from '@store/family/tableFamily';
import { selectedLanguageOptionState } from '@store/faqState';
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function Faq() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { openModal } = useModal();

  const currentPage = useRecoilValue(currentPageFamily('faq'));
  const locale = useRecoilValue(selectedLanguageOptionState);

  const { data, loading } = useQuery(GET_FAQ, {
    variables: { id: Number(id) },
    onCompleted: ({ faq }) => {
      return;
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '자주 묻는 질문 조회에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
  });

  const [deleteFaq] = useMutation(DELETE_FAQ, {
    onCompleted: (data) => {
      alert('삭제되었습니다.');
      navigate(Paths.FaqBoard);
    },

    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '자주 묻는 질문 삭제를 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
    refetchQueries: [
      {
        query: SEARCH_FAQS,
        variables: makeFaqVariables({
          listLocale: locale,
          currentPage,
          selectedSearchOption: 'OR',
          keyword: '',
          perPage: 10,
        }),
      },
    ],
  });

  const onDeleteClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '삭제',
      message: '해당 글을 삭제하시겠습니까?',
      confirm: () => {
        deleteFaq({ variables: { id: id } });
      },
    });
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <Box px={8} minW="900px">
      <Title title="자주 하는 질문" />
      <ContentWrapper>
        <BgWrapper>
          <Flex
            pt={2}
            pb={2}
            pl={5}
            pr={7}
            borderY="1px"
            borderColor="gray.300"
            bg="gray.100"
            justifyContent="space-between"
          >
            <Box fontWeight="semibold" fontSize="lg">
              {data.faq.subject}
            </Box>
          </Flex>
          <Box pt={2} pb={2} pl={5} pr={7} borderBottom="1px" borderColor="gray.300">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.faq.content) }} />
          </Box>
          <Flex mt={5} justifyContent={'space-between'}>
            <NavigationButtons listPath={Paths.FaqBoard} navigatePath={Paths.Faq} data={data.faq} />
            <ButtonGroup colorScheme="teal">
              <Button colorScheme="teal" onClick={onDeleteClick}>
                삭제
              </Button>
              <Button
                variant="outline"
                onClick={() =>
                  navigate(`${Paths.UpdateFaq.replace(':id', String(id))}`, {
                    state: data.faq,
                  })
                }
              >
                수정
              </Button>
            </ButtonGroup>
          </Flex>
        </BgWrapper>
      </ContentWrapper>
    </Box>
  );
}

export default Faq;
