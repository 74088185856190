import { useLazyQuery } from '@apollo/client';
import { MODAL_TYPES } from '@components/modals/Modals';
import { SEARCH_NOTICES } from '@graphql/notice';
import useModal from '@hooks/useModal';
import { atom, selector, useSetRecoilState } from 'recoil';
import {
  Image,
  NoticeFilter,
  NoticeType,
  PaginatorInfoType,
  User,
  initialPageInfo,
  noticeCreateInputType,
  noticeUpdateInputType,
} from '../types';
import { currentPageFamily, perPageFamily } from './family/tableFamily';

export const idState = atom<string>({
  key: 'noticeContentState/idState',
  default: undefined,
});

export const subjectState = atom<string>({
  key: 'noticeContentState/subjectState',
  default: '',
});

export const contentState = atom<string>({
  key: 'noticeContentState/contentState',
  default: '',
});

export const isFixedState = atom<number>({
  key: 'noticeContentState/isFixedState',
  default: Number(false),
});

export const isPopUpState = atom<number>({
  key: 'noticeContentState/isPopUpState',
  default: Number(false),
});

export const isActiveState = atom<number>({
  key: 'noticeContentState/isActiveState',
  default: Number(true),
});

export const startedAtState = atom<string | null>({
  key: 'noticeContentState/startedAtState',
  default: null,
});

export const endedAtState = atom<string | null>({
  key: 'noticeContentState/endedAtState',
  default: null,
});

export const createdAtState = atom<Date>({
  key: 'noticeContentState/createdAtState',
  default: undefined,
});

export const updatedAtState = atom<Date>({
  key: 'noticeContentState/updatedAtState',
  default: undefined,
});

// 조회용 이미지, 섬네일
export const imagesState = atom<Image[]>({
  key: 'noticeContentState/imagesState',
  default: undefined,
});

export const thumbnailState = atom<Image | undefined>({
  key: 'noticeContentState/thumbnailState',
  default: undefined,
});

// 등록, 수정용 이미지, 섬네일 id
export const uploadedImageState = atom<number[]>({
  key: 'noticeContentState/uploadedImageState',
  default: [],
});

export const uploadedThumbnailState = atom<number | undefined>({
  key: 'noticeContentState/uploadedThumbnailState',
  default: undefined,
});

export const authorState = atom<User | undefined>({
  key: 'noticeContentState/authorState',
  default: undefined,
});

export const noticeTypeState = atom<NoticeFilter>({
  key: 'noticeTypeState/noticeState',
  default: NoticeFilter.NOTICE,
});

export const localeState = atom<string>({
  key: 'noticeContentState/localeState',
  default: 'KOREAN',
});

export const previousState = atom<{ id: string; subject: string }>({
  key: 'noticeContentState/previousState',
  default: undefined,
});

export const nextState = atom<{ id: string; subject: string }>({
  key: 'noticeContentState/nextState',
  default: undefined,
});

export const deleteImagesState = atom<number[]>({
  key: 'noticeContentState/deleteImagesState',
  default: [],
});

export const deleteThumbnailState = atom<number | undefined>({
  key: 'noticeContentState/deleteThumbnailState',
  default: undefined,
});

export const noticeState = selector<NoticeType>({
  key: 'noticeContentState/noticeState',
  get: ({ get }) => {
    return {
      id: get(idState),
      subject: get(subjectState),
      content: get(contentState),
      is_fixed: get(isFixedState),
      is_popup: get(isPopUpState),
      is_active: get(isActiveState),
      started_at: get(startedAtState),
      ended_at: get(endedAtState),
      created_at: get(createdAtState),
      updated_at: get(updatedAtState),
      images: get(imagesState),
      thumbnail: get(thumbnailState),
      author: get(authorState),
      locale: get(localeState),
      previous: get(previousState),
      next: get(nextState),
      type: get(noticeTypeState),
    };
  },
});

export const noticeCreateInputState = selector<noticeCreateInputType>({
  key: 'noticeContentState/noticeCreateInputState',
  get: ({ get }) => {
    return {
      subject: get(subjectState),
      content: get(contentState),
      is_fixed: get(isFixedState),
      is_popup: get(isPopUpState),
      is_active: get(isActiveState),
      started_at: get(startedAtState),
      ended_at: get(endedAtState),
      images: get(uploadedImageState),
      thumbnail: get(uploadedThumbnailState),
      locale: get(localeState),
      type: get(noticeTypeState),
    };
  },
});

export const noticeUpdateInputState = selector<noticeUpdateInputType>({
  key: 'noticeContentState/noticeUpdateInputState',
  get: ({ get }) => {
    return {
      id: get(idState),
      subject: get(subjectState),
      content: get(contentState),
      is_fixed: get(isFixedState),
      is_popup: get(isPopUpState),
      is_active: get(isActiveState),
      started_at: get(startedAtState),
      ended_at: get(endedAtState),
      images: get(uploadedImageState),
      thumbnail: get(uploadedThumbnailState),
      locale: get(localeState),
      deleteImages: get(deleteImagesState),
      deleteThumbnail: get(deleteThumbnailState),
      type: get(noticeTypeState),
    };
  },
});

//조회용
export const noticeListState = atom<NoticeType[]>({
  key: 'noticeContentState/noticeListState',
  default: [],
});

export const paginatorInfoState = atom<PaginatorInfoType>({
  key: 'noticeContentState/paginatorInfoState',
  default: initialPageInfo,
});

export const noticeListInfoState = selector<{
  data: NoticeType[];
  paginatorInfo: PaginatorInfoType;
}>({
  key: 'noticeContentState/noticeListInfoState',
  get: ({ get }) => {
    return {
      data: get(noticeListState),
      paginatorInfo: get(paginatorInfoState),
    };
  },
});

//검색용
export const selectedLanguageOptionState = atom<string>({
  key: 'noticeContentState/selectedLanguageOptionState',
  default: 'ALL',
});

export const selectedSearchOptionState = atom<string>({
  key: 'noticeContentState/selectedSearchOptionState',
  default: 'OR',
});

export const selectedStatusOptionState = atom<string>({
  key: 'noticeContentState/selectedStatusOption',
  default: 'ALL',
});

export const keywordState = atom<string>({
  key: 'noticeContentState/keyword',
  default: '',
});

interface noticeSearchVariableType {
  selectedLanguageOption: string;
  currentPage: number | string;
  perPage: number | string;
  selectedSearchOption: string;
  keyword: string;
}

export const noticeSearchVariableState = selector<noticeSearchVariableType>({
  key: 'noticeContentState/noticeSearchState',
  get: ({ get }) => {
    return {
      selectedLanguageOption: get(selectedLanguageOptionState),
      currentPage: get(currentPageFamily('notice')),
      perPage: get(perPageFamily('notice')),
      selectedSearchOption: get(selectedSearchOptionState),
      keyword: get(keywordState),
    };
  },
});

export function useSearchNotices() {
  const setPaginatorInfo = useSetRecoilState(paginatorInfoState);
  const setNoticeList = useSetRecoilState(noticeListState);
  const { openModal } = useModal();

  const [searchNotices, { loading, error }] = useLazyQuery(SEARCH_NOTICES, {
    onCompleted: ({ noticeSearch }) => {
      setNoticeList(noticeSearch.data);
      setPaginatorInfo(noticeSearch.paginatorInfo);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '공지사항 검색을 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
  });

  return { searchNotices, loading, error };
}

// 내 기보를 소유하고 싶다
// 내가둔 수를 바라보고 싶다.
