import {
  contentState,
  endedAtState,
  imagesState,
  isActiveState,
  isFixedState,
  isPopUpState,
  localeState,
  noticeTypeState,
  startedAtState,
  subjectState,
  thumbnailState,
  uploadedImageState,
  uploadedThumbnailState,
} from '@store/noticeState';
import { useResetRecoilState } from 'recoil';

function useResetNotice() {
  const resetSubject = useResetRecoilState(subjectState);
  const resetContent = useResetRecoilState(contentState);
  const resetIsFixed = useResetRecoilState(isFixedState);
  const resetIsPopUp = useResetRecoilState(isPopUpState);
  const resetIsActive = useResetRecoilState(isActiveState);
  const resetStartedAt = useResetRecoilState(startedAtState);
  const resetEndedAt = useResetRecoilState(endedAtState);
  const resetImages = useResetRecoilState(imagesState);
  const resetUploadedImage = useResetRecoilState(uploadedImageState);
  const resetThumbnail = useResetRecoilState(thumbnailState);
  const resetUploadedThumbnailState = useResetRecoilState(uploadedThumbnailState);
  const resetLocale = useResetRecoilState(localeState);
  const resetTypeFilter = useResetRecoilState(noticeTypeState);

  const resetNoticeState = () => {
    resetSubject();
    resetContent();
    resetIsFixed();
    resetIsPopUp();
    resetIsActive();
    resetStartedAt();
    resetEndedAt();
    resetImages();
    resetUploadedImage();
    resetThumbnail();
    resetUploadedThumbnailState();
    resetLocale();
    resetTypeFilter();
  };
  return resetNoticeState;
}

export default useResetNotice;
