// Paths 내의 하위 pathname을 allowedUrls에 추가
export const getAllowedUrls = (
  pathsObject: { [s: string]: string } | ArrayLike<string>,
  allowedUrlsArray: string[],
) => {
  let totalAllowedUrls = [...allowedUrlsArray];
  Object.values(pathsObject).forEach((path) => {
    const parentPath = path.substring(0, path.lastIndexOf('/'));
    if (allowedUrlsArray.includes(parentPath)) {
      const matchingPaths = Object.values(pathsObject)
        .filter((p) => p.startsWith(parentPath))
        .filter((p) => !totalAllowedUrls.includes(p));
      totalAllowedUrls.push(...matchingPaths);
    }
  });
  return totalAllowedUrls;
};

// pathname 내의 number -> ":id" 로 변경
export const replaceNumberToStringId = (pathname: string) => {
  return pathname.replace(/\d+/g, ':id');
};
