import { gql } from '@apollo/client';

const UPLOAD = gql`
  mutation ($file: Upload!) {
    upload(file: $file) {
      id
      url
    }
  }
`;

export { UPLOAD };
