import MenuButtons from '@articles/MenuButtons';
import NavItem from '@articles/NavItem';
import { Flex } from '@chakra-ui/react';
import { Paths } from '@pages/Router';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <Flex
      direction="column"
      px="4"
      py="4"
      gap={3}
      position="sticky"
      top={18}
      minH={'100vh'}
      minW={56}
      borderRight="2px solid #f5f5f5"
    >
      <NavItem title="대시보드" active={pathname === Paths.Dashboard} path={Paths.Dashboard} />
      <MenuButtons
        currentPath={pathname}
        active={pathname === Paths.ImportList || pathname === Paths.ExportList}
        title="스왑내역"
        info={[
          {
            path: Paths.ImportList,
            title: '충전 내역',
          },
          {
            path: Paths.ExportList,
            title: '사용 내역',
          },
        ]}
      />
      <MenuButtons
        currentPath={pathname}
        active={pathname.includes(Paths.NoticeBoard) || pathname.includes(Paths.FaqBoard)}
        title="고객센터"
        info={[
          {
            path: Paths.NoticeBoard,
            title: '공지사항',
          },
          {
            path: Paths.FaqBoard,
            title: '자주 하는 질문',
          },
        ]}
      />
    </Flex>
  );
};

export default Sidebar;
