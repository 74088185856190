import { Status } from '@/types/index';
import { useLazyQuery } from '@apollo/client';
import Pagination from '@articles/Pagination';
import NoResult from '@atoms/NoResult';
import PerpageSelect from '@atoms/PerpageSelect';
import XLSDownloadButton from '@atoms/XLSDownloadButton';
import { TableWrapper, Td } from '@atoms/table';
import { Flex, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import { exportListDownloadHeader } from '@const/table';
import { EXPORT_LIST, makeSwapVariables } from '@graphql/transaction';
import useShrinkHeader from '@hooks/useShrinkHeader';
import TableHeader from '@sections/TableHeader';
import { useExportsData } from '@store/exportListState';
import {
  Tables,
  currentPageFamily,
  currentTableInfoFamily,
  perPageFamily,
} from '@store/family/tableFamily';
import { useCurrentPageId } from '@store/userState';
import { addCommas, parseDownloadExportsData, parseSwapStatus } from '@utils/index';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

interface Props {
  pageId: number;
}

const ExportsTable: React.FC<Props> = ({ pageId }) => {
  const [perPage, setPerPage] = useRecoilState(perPageFamily(Tables.Export));
  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily(Tables.Export));
  const { tStartDate, tEndDate, tKeyword, tStatus, tSearchOption, tCoinOption } = useRecoilValue(
    currentTableInfoFamily(Tables.Export),
  );

  const [shrinkList, handleDoubleClickHeader] = useShrinkHeader();
  const { dataList, paginatorInfo, summaryInfo, getExportList } = useExportsData();

  const [download] = useLazyQuery(EXPORT_LIST);
  const onDownloadClick = async () => {
    const { data } = await download({
      variables: makeSwapVariables({
        startDate: tStartDate,
        endDate: tEndDate,
        keyword: tKeyword,
        status: tStatus,
        searchOption: tSearchOption,
        coinOption: tCoinOption,
        perPage: paginatorInfo.total,
        page: 1,
        pageId,
      }),
    });

    const list = data['adminExportSwaps'].data;
    return parseDownloadExportsData(list);
  };

  const handleCurrentPage = (value: number) => setCurrentPage(value);
  const handlePerPage = (value: number) => {
    setPerPage(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    getExportList({
      variables: makeSwapVariables({
        startDate: tStartDate,
        endDate: tEndDate,
        keyword: tKeyword,
        status: tStatus,
        searchOption: tSearchOption,
        coinOption: tCoinOption,
        perPage: perPage,
        page: currentPage,
        pageId,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage]);

  return (
    <>
      <Flex justifyContent="space-between" gap={4} alignItems={'center'}>
        <PerpageSelect handleChange={handlePerPage} />
        <Flex alignItems="center" gap={4}>
          <Text>
            검색 <b>{addCommas(paginatorInfo.total)}</b>개 (총 {addCommas(summaryInfo.total_count)}
            개)
          </Text>
          <XLSDownloadButton
            onDownloadClick={onDownloadClick}
            headers={exportListDownloadHeader}
            fileName="사용 내역"
          />
        </Flex>
      </Flex>

      <TableWrapper>
        <Table size="md">
          <TableHeader
            headerInfos={exportListDownloadHeader}
            shrinkList={shrinkList}
            onDoubleClick={handleDoubleClickHeader}
          />
          <Tbody fontSize="sm">
            {dataList.map(
              (
                {
                  acronym,
                  amount,
                  canceled_at,
                  created_at,
                  email,
                  fee,
                  order_no,
                  original_amount,
                  partner_id,
                  partner_order_no,
                  reward,
                  swap_status,
                },
                i,
              ) => {
                const number = paginatorInfo.total - i - (currentPage - 1) * perPage;

                if (number >= 1) {
                  return (
                    <Tr
                      key={`${order_no} -${i}`}
                      color={swap_status === 'CANCEL' ? 'gray' : 'black'}
                    >
                      <Td color="black">{number}</Td>
                      <Td>TMOP</Td>
                      <Td>{'->'}</Td>
                      <Td>{acronym}</Td>
                      <Td>{email || '-'}</Td>
                      <Td>{partner_id || '-'}</Td>
                      <Td>{shrinkList.includes('orderNo') ? '...' : order_no || '-'}</Td>
                      <Td>
                        {shrinkList.includes('partnerOrderNo') ? '...' : partner_order_no || '-'}
                      </Td>
                      <Td>{addCommas(original_amount)} P</Td>
                      <Td>{addCommas(fee)} P</Td>
                      <Td>{addCommas(amount)} P</Td>
                      <Td>{addCommas(reward)} TMOP</Td>
                      <Td color={swap_status === 'CANCEL' ? 'red.400' : 'black'}>
                        {parseSwapStatus(swap_status)}
                      </Td>
                      <Td>{shrinkList.includes('completedAt') ? '...' : created_at || '-'}</Td>
                      <Td>{shrinkList.includes('canceledAt') ? '...' : canceled_at || '-'}</Td>
                    </Tr>
                  );
                } else return null;
              },
            )}
          </Tbody>
        </Table>
        {dataList.length === 0 && <NoResult />}
      </TableWrapper>
      <Pagination
        perPage={perPage}
        perList={5}
        totalLength={paginatorInfo.total}
        currentPage={currentPage}
        onPageChange={handleCurrentPage}
      />
    </>
  );
};

export default ExportsTable;
