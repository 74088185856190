import { useMutation } from '@apollo/client';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { DELETE_NOTICE, SEARCH_NOTICES, UPDATE_NOTICE } from '@graphql/notice';
import useModal from '@hooks/useModal';
import client from '@services/index';
import { noticeListInfoState } from '@store/noticeState';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  selectedIds: string[];
  onSelectedIdsChange: (value: string[]) => void;
  totalSelectedIds: string[];
  onTotalSelectedIdsChange: (value: string[]) => void;
}
const SelectedButtonGroup: React.FC<Props> = ({
  selectedIds,
  onSelectedIdsChange,
  totalSelectedIds,
  onTotalSelectedIdsChange,
}) => {
  const noticeListInfo = useRecoilValue(noticeListInfoState);
  const { openModal } = useModal();

  const [updateNotice] = useMutation(UPDATE_NOTICE);
  const [deleteNotice] = useMutation(DELETE_NOTICE);

  const handleDeleteChecked = async () => {
    if (!selectedIds.length) {
      return;
    }
    try {
      for (const id of totalSelectedIds) {
        await deleteNotice({ variables: { id } });
      }
      onSelectedIdsChange([]);
      onTotalSelectedIdsChange([]);
      alert('선택한 공지사항이 삭제되었습니다.');
      await client.refetchQueries({
        include: [SEARCH_NOTICES],
      });
    } catch (error) {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '공지사항 삭제에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          onSelectedIdsChange([]);
          onTotalSelectedIdsChange([]);
        },
      });
    }
  };

  const handleIsActiveChange = async (isActive: boolean) => {
    if (!selectedIds.length) {
      return;
    }
    const selectedNotices = noticeListInfo.data.filter((notice) => {
      return selectedIds.includes(notice.id);
    });

    try {
      for (const id of totalSelectedIds) {
        const selectedNotice = selectedNotices.filter((notice) => {
          return notice.id === id;
        });
        await updateNotice({
          variables: {
            input: { id: id, is_active: Number(isActive), locale: selectedNotice[0].locale },
          },
        });
      }
      onSelectedIdsChange([]);
      onTotalSelectedIdsChange([]);
      alert(`선택한 공지사항이 ${isActive ? '노출' : '숨김'} 처리되었습니다.`);
      await client.refetchQueries({
        include: [SEARCH_NOTICES],
      });
    } catch (error) {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: `공지사항  ${
          isActive ? '노출' : '숨김'
        }에 실패했습니다. 잠시 후 다시 시도하십시오.`,
        error: () => {
          onSelectedIdsChange([]);
          onTotalSelectedIdsChange([]);
        },
      });
    }
  };

  const onNoSelectedClick = (type: string) => {
    openModal({
      type: MODAL_TYPES.alert,
      title: `선택 ${type}`,
      message: '선택된 게시글이 없습니다.',
      alert: () => {
        return;
      },
    });
  };

  const onSelectedClick = (type: string) => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: `선택 ${type}`,
      message: `${totalSelectedIds.length}개의 게시글을 ${type}하시겠습니까?`,
      confirm: () => {
        if (type === '삭제') {
          handleDeleteChecked();
        } else if (type === '숨김') {
          handleIsActiveChange(false);
        } else if (type === '노출') {
          handleIsActiveChange(true);
        }
      },
    });
  };

  return (
    <ButtonGroup colorScheme="teal" variant="outline" mt={5}>
      {totalSelectedIds.length === 0 ? (
        <>
          <Button onClick={() => onNoSelectedClick('삭제')}>선택 삭제</Button>
          <Button onClick={() => onNoSelectedClick('숨김')}>선택 숨김</Button>
          <Button onClick={() => onNoSelectedClick('노출')}>선택 노출</Button>
        </>
      ) : (
        <>
          <Button onClick={() => onSelectedClick('삭제')}>선택 삭제</Button>
          <Button onClick={() => onSelectedClick('숨김')}>선택 숨김</Button>
          <Button onClick={() => onSelectedClick('노출')}>선택 노출</Button>
        </>
      )}
    </ButtonGroup>
  );
};

export default SelectedButtonGroup;
