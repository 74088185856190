import { gql } from '@apollo/client';

export const SEARCH_OPTIONS = gql`
  query ($pageId: Int!) {
    adminSearchOption(admin_page_id: $pageId) {
      import {
        coin_id
        name
        option {
          key
          value
        }
      }
      export {
        coin_id
        name
        option {
          key
          value
        }
      }
    }
  }
`;
