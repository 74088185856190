import { useLazyQuery } from '@apollo/client';
import { MODAL_TYPES } from '@components/modals/Modals';
import { SEARCH_FAQS } from '@graphql/faq';
import useModal from '@hooks/useModal';
import { atom, selector, useRecoilState } from 'recoil';
import {
  FaqType,
  LanguageOption,
  PaginatorInfoType,
  SearchOption,
  initialPageInfo,
} from './../types/index';
import { currentPageFamily } from './family/tableFamily';

export const orderByNoState = atom<number>({
  key: 'faqContentState/orderByNoState',
  default: undefined,
});

//조회용
export const faqListState = atom<FaqType[]>({
  key: 'faqContentState/faqListState',
  default: [],
});

export const paginatorInfoState = atom<PaginatorInfoType>({
  key: 'faqContentState/paginatorInfoState',
  default: initialPageInfo,
});

export const selectedSearchOptionState = atom<SearchOption>({
  key: 'faqContentState/selectedSearchOptionState',
  default: 'OR',
});

export const selectedLanguageOptionState = atom<LanguageOption>({
  key: 'faqContentState/selectedLanguageOptionState',
  default: 'KOREAN',
});

export const keywordState = atom<string>({
  key: 'faqContentState/keyword',
  default: '',
});

interface faqSearchVariableType {
  selectedLanguageOption: LanguageOption;
  currentPage: number | string;
  selectedSearchOption: SearchOption;
  keyword: string;
}

export const faqSearchVariableState = selector<faqSearchVariableType>({
  key: 'faqContentState/faqSearchState',
  get: ({ get }) => {
    return {
      selectedLanguageOption: get(selectedLanguageOptionState),
      currentPage: get(currentPageFamily('faq')),
      selectedSearchOption: get(selectedSearchOptionState),
      keyword: get(keywordState),
    };
  },
});

export function useSearchFaqs() {
  const [paginatorInfo, setPaginatorInfo] = useRecoilState(paginatorInfoState);
  const [faqList, setFaqList] = useRecoilState(faqListState);

  const { openModal } = useModal();

  const [searchFaqs, { loading, error, refetch }] = useLazyQuery(SEARCH_FAQS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ faqSearch }) => {
      setFaqList(faqSearch.data);
      setPaginatorInfo(faqSearch.paginatorInfo);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '자주 하는 질문 검색을 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
  });
  return { searchFaqs, faqList, setFaqList, paginatorInfo, loading, error, refetch };
}
