import { Box, Button, Flex } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import useModal from '@hooks/useModal';
import { Paths } from '@pages/Router';
import { userInfoState } from '@store/userState';
import { removeLoginInfo } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Header = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const userInfo = useRecoilValue(userInfoState);

  const onLogoutClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: 'Logout',
      message: '로그아웃 하시겠습니까?',
      confirm: () => {
        removeLoginInfo();
        navigate(Paths.Login);
      },
    });
  };

  return (
    <Flex
      height={16}
      align={'center'}
      justify="space-between"
      p={4}
      borderBottom="2px solid #f5f5f5"
      mx={2}
    >
      <Box fontFamily="monospace" fontSize={20} fontWeight={600}>
        TMO-Admin
      </Box>
      <Flex alignItems={'center'} gap={4}>
        <div>{userInfo.email}</div>
        <Button colorScheme="red" onClick={onLogoutClick}>
          Logout
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;
