import styled from '@emotion/styled';

function NoResult() {
  return <NoDataWrapper>검색 결과가 없습니다.</NoDataWrapper>;
}

export default NoResult;

const NoDataWrapper = styled.div`
  margin: 70px auto;
  text-align: center;
`;
