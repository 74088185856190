import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(input: { email: $email, password: $password }) {
      access_token
      expires_in
      user {
        id
        email
        adminPages {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
          parent {
            id
            parent_id
            url
            name
            depth
            order_by_no
            is_folding_menu
          }
        }
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      access_token
      expires_in
    }
  }
`;

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      coin {
        id
      }
      adminPages {
        id
        parent_id
        url
        name
        depth
        order_by_no
        is_folding_menu
        parent {
          id
          parent_id
          url
          name
          depth
          order_by_no
          is_folding_menu
        }
      }
    }
  }
`;
