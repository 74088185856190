import { LATEST_UPDATE } from '@const/index';
import { LanguageOptionList } from '@const/language';
import { format } from 'date-fns';

export function addCommas(_number: number): string {
  return _number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseCreatedAtObj(_startDate: Date | null, _endDate: Date | null) {
  if (!_startDate || !_endDate) return null;
  return {
    from: format(_startDate, 'yyyy-MM-dd 00:00:00'),
    to: format(_endDate, 'yyyy-MM-dd 23:59:59'),
  };
}

export function convertStringToDate(
  startedAt: string | number | Date,
  endedAt: string | number | Date,
) {
  let startDate;
  let endDate;
  if (startedAt && endedAt) {
    startDate = new Date(startedAt);
    endDate = new Date(endedAt);
  } else {
    startDate = null;
    endDate = null;
  }

  return { startDate, endDate };
}

// default 가 전체를 의미하는경우 서버와 빈값으로 소통 프론트에서는 ALL값으로 관리
export function parseCoinOption(_coinOption: string): number | null {
  return _coinOption === 'ALL' ? null : Number(_coinOption);
}

export function createYearArray(fromYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let year = fromYear; year <= currentYear; year++) {
    yearArray.push(year);
  }

  return yearArray.reverse();
}

export function createMonthArray(_selectedYear: number): number[] {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript의 월은 0부터 시작하므로 1을 더해줍니다.

  const monthArray = [];

  for (let month = 1; month <= 12; month++) {
    if (currentYear === _selectedYear) {
      if (month <= currentMonth) {
        monthArray.push(month);
      }
    } else {
      monthArray.push(month);
    }
  }

  return monthArray.reverse();
}

export const getLatestUpdateTime = () => localStorage.getItem(LATEST_UPDATE);
export const setLatestUpdateTime = (date: string) => localStorage.setItem(LATEST_UPDATE, date);

export const getLanguageValueByKey = (key: string): string | undefined => {
  const selectedOption = LanguageOptionList.find((option) => option.key === key);
  return selectedOption?.value;
};

export * from './parser';
export * from './token';
