import { ExportsData, ExportsSummaryType, PaginatorInfoType, initialPageInfo } from '@/types/index';
import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client';
import { EXPORT_LIST } from '@graphql/transaction';
import { atom, useRecoilState } from 'recoil';

export const dataListState = atom<ExportsData[]>({
  key: 'exportListState/dataListState',
  default: [],
});

export const paginatorInfoState = atom<PaginatorInfoType>({
  key: 'exportListState/paginatorInfoState',
  default: initialPageInfo,
});

export const summaryInfoState = atom<ExportsSummaryType>({
  key: 'exportListState/summaryInfo',
  default: {
    total_count: 0,
    completed_count: 0,
    cancel_count: 0,
    export_amount: 0,
    import_amount: 0,
    reward_amount: 0,
    fee: 0,
  },
});

export function useExportsData(): {
  summaryInfo: ExportsSummaryType;
  getExportList: LazyQueryExecFunction<any, OperationVariables>;
  dataList: ExportsData[];
  paginatorInfo: PaginatorInfoType;
} {
  const [paginatorInfo, setPaginatorInfo] = useRecoilState(paginatorInfoState);
  const [dataList, setDataList] = useRecoilState(dataListState);
  const [summaryInfo, setSummaryInfo] = useRecoilState(summaryInfoState);

  const [getExportList] = useLazyQuery(EXPORT_LIST, {
    onCompleted: ({ adminExportSwaps, adminExportSwapAmount }) => {
      setSummaryInfo(adminExportSwapAmount);
      setPaginatorInfo(adminExportSwaps.paginatorInfo);
      setDataList(adminExportSwaps.data);
    },
  });

  return { summaryInfo, getExportList, dataList, paginatorInfo };
}
