import SummaryItem from '@articles/SummaryItem';
import { Flex } from '@chakra-ui/react';
import { useImportsData } from '@store/importListState';

const ImportsSummary = () => {
  const { summaryInfo, paginatorInfo } = useImportsData();

  return (
    <Flex flexDir="row" justifyContent={'space-between'} px={5}>
      <SummaryItem title="건 수" value={paginatorInfo.total} symbol="건" />
      <SummaryItem title="완료 수" value={summaryInfo.completed_count} symbol="건" />
      <SummaryItem title="취소 수" value={summaryInfo.cancel_count} symbol="건" />
      <SummaryItem title="충전 티모 포인트" value={summaryInfo.import_amount} symbol="TMOP" />
      <SummaryItem title="사용 제휴사 포인트" value={summaryInfo.export_amount} symbol="P" />
      <SummaryItem title="수수료" value={summaryInfo.fee} symbol="P" />
    </Flex>
  );
};

export default ImportsSummary;
