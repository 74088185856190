import { Box, Text } from '@chakra-ui/react';
import { addCommas } from '@utils/index';
import React from 'react';

interface Props {
  title: string;
  value: number;
  symbol?: string;
}
const SummaryItem: React.FC<Props> = ({ title, value, symbol }) => {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        {title}
      </Text>
      <Text fontSize="xl" fontWeight="bold">
        {addCommas(Number(value.toFixed(2)))}{' '}
        <span style={{ fontWeight: 500, fontSize: '20px' }}>{symbol}</span>
      </Text>
    </Box>
  );
};

export default SummaryItem;
