import Title from '@atoms/Title';
import { Box } from '@chakra-ui/react';

const DashBoard = () => {
  return (
    <Box>
      <Title title="투데이 현황" />
      {/* <Flex gap={4} justifyContent="space-between" mt={6}>
        <Card title="충전 수량" bgColor="blackAlpha.900" todayAmount={0} totalAmount={0} />
        <Card title="사용 수량" bgColor="blackAlpha.800" todayAmount={0} totalAmount={0} />
        <Card title="리워드 수량" bgColor="blackAlpha.700" todayAmount={0} totalAmount={0} />
        <Card title="수수료 (티모월렛)" bgColor="blackAlpha.600" todayAmount={0} totalAmount={0} />
        <Card title="수수료 (댐댐)" bgColor="blackAlpha.500" todayAmount={0} totalAmount={0} />
      </Flex> */}
    </Box>
  );
};

export default DashBoard;
