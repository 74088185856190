import { Status } from '@/types/index';
import DatePickerRow from '@articles/DatePickerRow';
import TitleSelect from '@atoms/TitleSelect';
import { TableFilterWrapper } from '@atoms/table';
import { Button, Flex, Input, InputGroup } from '@chakra-ui/react';
import { statusOptions } from '@const/filter';
import { makeSwapVariables } from '@graphql/transaction';
import useDateRange from '@hooks/useDateRange';
import useInput from '@hooks/useInput';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import useSearchOptions from '@hooks/useSearchOptions';
import {
  Tables,
  currentPageFamily,
  currentTableInfoFamily,
  perPageFamily,
} from '@store/family/tableFamily';
import { useImportsData } from '@store/importListState';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

interface Props {
  pageId: number;
}

const ImportsFilter: React.FC<Props> = ({ pageId }) => {
  const perPage = useRecoilValue(perPageFamily(Tables.Import));
  const setCurrentPage = useSetRecoilState(currentPageFamily(Tables.Import));
  const [currentTableInfo, updateTableInfo] = useRecoilState(currentTableInfoFamily(Tables.Import));

  const [keyword, handleKeyword, setKeyword] = useInput('');
  const [swapStatus, setSwapStatus] = useState<Status>('ALL');
  const [coinOptionStatus, setCoinOptionStatus] = useState('');
  const [searchOptionStatus, setSearchOptionStatus] = useState('');

  const { getImportList } = useImportsData();
  const { coinOptions, searchOptions } = useSearchOptions(Tables.Import);
  const { startDate, endDate, handleStartDate, handleEndDate } = useDateRange(
    currentTableInfo.tStartDate,
    currentTableInfo.tEndDate,
  );

  const onSearchClick = () => {
    getImportList({
      variables: makeSwapVariables({
        startDate: startDate,
        endDate: endDate,
        keyword: keyword,
        status: swapStatus,
        coinOption: coinOptionStatus,
        searchOption: searchOptionStatus,
        perPage: perPage,
        page: 1,
        pageId,
      }),
    });

    updateTableInfo({
      tStartDate: startDate,
      tEndDate: endDate,
      tKeyword: keyword,
      tStatus: swapStatus,
      tSearchOption: searchOptionStatus,
      tCoinOption: coinOptionStatus,
    });

    setCurrentPage(1);
  };
  const handleKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);

  const handleSearchOptions = (value: string) => setSearchOptionStatus(value);
  const handleSwapStatus = (value: Status) => setSwapStatus(value);
  const handleCoinOptions = (value: string) => {
    const initialSearchOption = searchOptions[value][0].key;

    setCoinOptionStatus(value);
    setSearchOptionStatus(initialSearchOption);
  };

  useLayoutEffect(() => {
    setSearchOptionStatus(currentTableInfo.tSearchOption);
    setCoinOptionStatus(currentTableInfo.tCoinOption);
    setKeyword(currentTableInfo.tKeyword);
    setSwapStatus(currentTableInfo.tStatus as Status);
  }, [
    setKeyword,
    currentTableInfo.tKeyword,
    currentTableInfo.tStatus,
    currentTableInfo.tSearchOption,
    currentTableInfo.tCoinOption,
  ]);

  //searchOption 로드 이후 && recoil내에 저장된 값이 없는경우
  useEffect(() => {
    if (searchOptions[coinOptionStatus]?.length > 0 && !currentTableInfo.tSearchOption) {
      setSearchOptionStatus(searchOptions[coinOptionStatus][0].key);
    }
  }, [coinOptionStatus, currentTableInfo.tSearchOption, searchOptions]);

  return (
    <TableFilterWrapper>
      <Flex gap={4}>
        <TitleSelect
          title="상태"
          onChange={handleSwapStatus}
          options={statusOptions}
          w={72}
          selectedValue={swapStatus}
        />
        <TitleSelect
          title="충전 코인"
          onChange={handleCoinOptions}
          options={coinOptions[Tables.Import]}
          w={72}
          selectedValue={coinOptionStatus}
        />
      </Flex>

      <Flex>
        <DatePickerRow
          title="검색 기간"
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />
      </Flex>

      <Flex gap={2}>
        <TitleSelect
          title="검색 조건"
          onChange={handleSearchOptions}
          options={searchOptions[coinOptionStatus]}
          selectedValue={searchOptionStatus}
          w="400px"
        />
        <InputGroup size="md" maxW="450px">
          <Input
            type="text"
            placeholder="내용을 입력하세요"
            value={keyword}
            onChange={handleKeyword}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>
        <Flex gap={2} justifyContent={'flex-end'} flex={1}>
          <Button variant="solid" colorScheme="teal" onClick={onSearchClick}>
            검색
          </Button>
          <Button variant="outline" colorScheme="teal" onClick={() => window.location.reload()}>
            초기화
          </Button>
        </Flex>
      </Flex>
    </TableFilterWrapper>
  );
};

export default ImportsFilter;
