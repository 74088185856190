import { ChakraProvider } from '@chakra-ui/react';
import Modals from '@components/modals/Modals';
import Router from '@pages/Router';
import LoginHandler from '@services/LoginHandler';
import UpdateChecker from '@services/UpdateChecker';
import theme from '@style/theme';

export const App = () => {
  // UpdateChecker();
  LoginHandler();

  return (
    <ChakraProvider theme={theme}>
      <Modals />
      <Router />
    </ChakraProvider>
  );
};
