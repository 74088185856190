import { Button, ButtonGroup } from '@chakra-ui/react';
import React from 'react';

interface Props {
  onCancelClick: () => void;
  onSubmitClick: () => void;
}

const SubmitButtonGroup: React.FC<Props> = ({ onCancelClick, onSubmitClick }) => {
  return (
    <ButtonGroup colorScheme="teal" justifyContent={'end'} variant="outline" mt={5}>
      <Button onClick={onCancelClick}>취소</Button>
      <Button onClick={onSubmitClick}>저장</Button>
    </ButtonGroup>
  );
};

export default SubmitButtonGroup;
