import { SearchOption } from '@/types/index';
import TitleSelect from '@atoms/TitleSelect';
import { TableFilterWrapper } from '@atoms/table';
import { Button, Flex, Input } from '@chakra-ui/react';
import { searchOptions } from '@const/filter';
import { makeFaqVariables } from '@graphql/faq';
import useKeyDown, { KeyboardKey } from '@hooks/useKeyDown';
import { Paths } from '@pages/Router';
import { currentPageFamily } from '@store/family/tableFamily';
import {
  keywordState,
  selectedLanguageOptionState,
  selectedSearchOptionState,
  useSearchFaqs,
} from '@store/faqState';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

interface Props {
  onEditOrderClick: () => void;
}

const FaqFilter: React.FC<Props> = ({ onEditOrderClick }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useRecoilState(currentPageFamily('faq'));
  const [selectedSearchOption, setSelectedSearchOption] = useRecoilState(selectedSearchOptionState);
  const selectedLanguageOption = useRecoilValue(selectedLanguageOptionState);
  const [keyword, setKeyword] = useRecoilState(keywordState);

  const { searchFaqs } = useSearchFaqs();

  const handleSearchOptions = (key: SearchOption) => setSelectedSearchOption(key);

  const onSearchClick = () => {
    setKeyword(keyword);
    searchFaqs({
      variables: makeFaqVariables({
        listLocale: selectedLanguageOption,
        currentPage,
        selectedSearchOption,
        keyword,
        perPage: 10,
      }),
    });
    setCurrentPage(1);
  };
  const onKeyDown = useKeyDown(() => onSearchClick(), [KeyboardKey.enter]);
  const onCreateButtonClick = () => navigate(Paths.CreateFaq);

  return (
    <TableFilterWrapper>
      <Flex gap={3} justifyContent={'space-between'}>
        <Flex gap={3} w={'70%'}>
          <TitleSelect
            title="검색 조건"
            onChange={handleSearchOptions}
            options={searchOptions}
            selectedValue={selectedSearchOption}
          />
          <Input
            size="md"
            maxW="550px"
            type="text"
            placeholder="내용을 입력하세요."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={onKeyDown}
          />
          <Button variant="outline" colorScheme="teal" onClick={onSearchClick}>
            검색
          </Button>
        </Flex>
        <Flex gap={3}>
          <Button variant="solid" colorScheme="teal" onClick={onEditOrderClick}>
            순서 편집
          </Button>
          <Button variant="outline" colorScheme="teal" onClick={onCreateButtonClick}>
            +글쓰기
          </Button>
        </Flex>
      </Flex>
    </TableFilterWrapper>
  );
};

export default FaqFilter;
