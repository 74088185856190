import { modalState } from '@store/modalState';
import { useRecoilState } from 'recoil';

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalState);

  const openModal = (props: any) => {
    const key = Math.random().toString(36).substring(2);

    const nextProps = {
      key,
      close: () => setModals([...modals]),
      ...props,
    };

    setModals([...modals].concat(nextProps));
  };

  return { openModal };
};

export default useModal;
