import { useMutation, useQuery } from '@apollo/client';
import NavigationButtons from '@articles/NavigationButtons';
import Title from '@atoms/Title';
import { Box, Button, ButtonGroup, Checkbox, Flex } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { DELETE_NOTICE, GET_NOTICE, SEARCH_NOTICES, makeNoticeVariables } from '@graphql/notice';
import useModal from '@hooks/useModal';
import useResetNotice from '@hooks/useResetNotice';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { perPageFamily } from '@store/family/tableFamily';
import {
  contentState,
  createdAtState,
  idState,
  imagesState,
  isActiveState,
  isFixedState,
  isPopUpState,
  localeState,
  nextState,
  noticeState,
  previousState,
  subjectState,
  thumbnailState,
} from '@store/noticeState';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { PreviewContainer } from './ThumbnailInput';

function Notice() {
  const navigate = useNavigate();
  const id = useParams();
  const { openModal } = useModal();

  const notice = useRecoilValue(noticeState);
  const setId = useSetRecoilState(idState);
  const setSubject = useSetRecoilState(subjectState);
  const setContent = useSetRecoilState(contentState);
  const setIsFixed = useSetRecoilState(isFixedState);
  const setIsPopup = useSetRecoilState(isPopUpState);
  const setIsActive = useSetRecoilState(isActiveState);
  const setCreatedAt = useSetRecoilState(createdAtState);
  const setImages = useSetRecoilState(imagesState);
  const setThumbnail = useSetRecoilState(thumbnailState);
  const setLocale = useSetRecoilState(localeState);
  const setPrevious = useSetRecoilState(previousState);
  const setNext = useSetRecoilState(nextState);
  const perPage = useRecoilValue(perPageFamily('notice'));

  const resetNoticeState = useResetNotice();

  const { error } = useQuery(GET_NOTICE, {
    variables: id,
    onCompleted: ({ notice }) => {
      setId(notice.id);
      setSubject(notice.subject);
      setContent(notice.content);
      setIsFixed(notice.is_fixed);
      setIsPopup(notice.is_popup);
      setIsActive(notice.is_active);
      setCreatedAt(notice.created_at);
      setImages(notice.images);
      setThumbnail(notice.thumbnail);
      setLocale(notice.locale);
      setPrevious(notice.previous);
      setNext(notice.next);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '공지사항 조회에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          return;
        },
      });
    },
  });

  const [deleteNotice] = useMutation(DELETE_NOTICE, {
    refetchQueries: [
      {
        query: SEARCH_NOTICES,
        variables: makeNoticeVariables({
          listLocale: 'ALL',
          isActive: 'ALL',
          currentPage: 1,
          perPage,
          selectedSearchOption: 'OR',
          keyword: '',
        }),
      },
    ],
    onCompleted: (data) => {
      alert('공지사항이 삭제되었습니다.');
      navigate(Paths.NoticeBoard);
      resetNoticeState();
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '공지사항 삭제를 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          resetNoticeState();
        },
      });
    },
  });

  const onDeleteClick = () => {
    openModal({
      type: MODAL_TYPES.confirm,
      title: '삭제',
      message: '공지사항을 삭제하시겠습니까?',
      confirm: () => {
        deleteNotice({ variables: id });
      },
    });
  };

  useEffect(() => {
    window.addEventListener('popstate', () => resetNoticeState());
    return () => {
      window.removeEventListener('popstate', () => resetNoticeState());
    };
  });

  if (error) {
    return <div></div>;
  }

  return (
    <Box px={8} minW="900px">
      <Title title="공지사항 리스트 > 상세보기" />
      <ContentWrapper>
        <BgWrapper>
          <Flex flexDirection={'column'} alignItems={'end'} m={3}>
            <Checkbox colorScheme="teal" size="md" isChecked={Boolean(notice.is_fixed)}>
              중요 공지
            </Checkbox>
            <Box>작성 일시: {String(notice.created_at)}</Box>
          </Flex>

          <Flex flexDirection={'column'}>
            <Flex
              pt={2}
              pb={2}
              pl={5}
              pr={7}
              borderY="1px"
              borderColor="gray.300"
              bg="gray.100"
              justifyContent="space-between"
            >
              <Box fontWeight="semibold" fontSize="lg">
                {notice.subject}
              </Box>
            </Flex>
            <Box pt={2} pb={2} pl={5} pr={7} borderBottom="1px" borderColor="gray.300">
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notice.content) }} />
            </Box>
            <Flex
              mt={10}
              pt={2}
              pb={2}
              pl={5}
              pr={7}
              borderY="1px"
              borderColor="gray.300"
              bg="gray.100"
              justifyContent="space-between"
            >
              <Box fontWeight="semibold" fontSize="lg">
                섬네일 이미지
              </Box>
            </Flex>
            <Box pt={2} pb={2} pl={5} pr={7} borderBottom="1px" borderColor="gray.300">
              {notice.thumbnail ? (
                <PreviewContainer src={notice.thumbnail.url} alt="thumbnail" />
              ) : (
                <div>등록된 섬네일이 없습니다.</div>
              )}
            </Box>
          </Flex>
          <Flex mt={5} justifyContent={'space-between'}>
            <NavigationButtons
              listPath={Paths.NoticeBoard}
              navigatePath={Paths.Notice}
              data={notice}
            />
            <ButtonGroup colorScheme="teal">
              <Button colorScheme="teal" onClick={onDeleteClick}>
                삭제
              </Button>
              <Button
                variant="outline"
                onClick={() => navigate(`${Paths.UpdateNotice.replace(':id', notice.id)}`)}
              >
                수정
              </Button>
            </ButtonGroup>
          </Flex>
        </BgWrapper>
      </ContentWrapper>
    </Box>
  );
}

export default Notice;
