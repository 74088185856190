import { useMutation } from '@apollo/client';
import QuillEditor from '@articles/QuillEditor';
import Title from '@atoms/Title';
import { Box, Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import { UPDATE_FAQ } from '@graphql/faq';
import useModal from '@hooks/useModal';
import { BgWrapper, ContentWrapper } from '@layout/index';
import { Paths } from '@pages/Router';
import { SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubmitButtonGroup from './SubmitButtonGroup';

function UpdateFaq() {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal } = useModal();

  const [images, setImages] = useState<number[]>([]);
  const [deleteImages, setDeleteImages] = useState<number[]>([]);
  const [faq, setFaq] = useState(location.state);

  const [updateFaq] = useMutation(UPDATE_FAQ, {
    onCompleted: ({ result }) => {
      alert('저장되었습니다.');
      navigate(`${Paths.Faq.replace(':id', faq.id)}`);
    },
    onError: (error) => {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '자주 하는 질문 수정을 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          navigate(Paths.FaqBoard);
        },
      });
    },
  });

  const getDeleteImages = () => {
    const updatedDeleteImages: number[] = [];
    for (const image of faq.images) {
      if (!faq.content.includes(image.url) && !updatedDeleteImages.includes(Number(image.id))) {
        updatedDeleteImages.push(Number(image.id));
      }
    }
    return updatedDeleteImages;
  };

  const handleSubmitClick = () => {
    if (faq.subject.length === 0 || faq.content.length === 0) {
      openModal({
        type: MODAL_TYPES.alert,
        title: '저장',
        message: '제목 및 내용을 입력하세요.',
        alert: () => {
          return;
        },
      });
    } else {
      openModal({
        type: MODAL_TYPES.confirm,
        title: '저장',
        message: '수정사항을 저장하시겠습니까?',
        confirm: () => {
          updateFaq({
            variables: {
              input: {
                id: faq.id,
                subject: faq.subject,
                content: faq.content,
                images: images,
                deleteImages,
              },
            },
          });
        },
      });
    }
  };

  const handleCancelClick = () => {
    openModal({
      type: MODAL_TYPES.alert,
      title: '취소',
      message: '수정을 취소하였습니다.',
      alert: () => {
        navigate(Paths.Faq.replace(':id', String(faq.id)));
      },
    });
  };

  const handleOnContentChange = (value: string) => {
    setFaq((prev: any) => ({ ...prev, content: value }));
  };

  const handleSetUploadedImages = (value: SetStateAction<number[]>) => {
    setImages(value);
  };

  useEffect(() => {
    const updatedDeleteImages = getDeleteImages();
    setDeleteImages(updatedDeleteImages);
  }, [faq, faq.images]);

  return (
    <Box px={8} minW="900px">
      <Title title="자주 하는 질문 수정" />
      <Flex flexDirection={'column'}>
        <ContentWrapper>
          <BgWrapper style={{ paddingBottom: '4rem' }}>
            <Box display="flex" justifyContent={'space-between'}>
              <InputGroup mb={2} width="60%">
                <InputLeftAddon children="제목" />
                <Input
                  type="text"
                  placeholder="제목을 입력하세요."
                  defaultValue={faq.subject}
                  onChange={(e) => {
                    setFaq((prev: any) => ({ ...prev, subject: e.target.value }));
                  }}
                />
              </InputGroup>
            </Box>
            <QuillEditor
              onChange={handleOnContentChange}
              onImageUpload={handleSetUploadedImages}
              defaultContent={faq.content}
            />
          </BgWrapper>
        </ContentWrapper>
        <SubmitButtonGroup onCancelClick={handleCancelClick} onSubmitClick={handleSubmitClick} />
      </Flex>
    </Box>
  );
}

export default UpdateFaq;
