import { useMutation } from '@apollo/client';
import { Button, Flex, FormControl, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { MODAL_TYPES } from '@components/modals/Modals';
import styled from '@emotion/styled';
import { UPLOAD_THUMBNAIL } from '@graphql/notice';
import useModal from '@hooks/useModal';
import { deleteThumbnailState, thumbnailState, uploadedThumbnailState } from '@store/noticeState';
import React, { useState } from 'react';
import { AiOutlineFileImage } from 'react-icons/ai';
import { useRecoilState, useSetRecoilState } from 'recoil';

function ThumbnailInput() {
  const { openModal } = useModal();
  const setUploadedThumbnailState = useSetRecoilState(uploadedThumbnailState);
  const [thumbnail, setThumbnail] = useRecoilState(thumbnailState);
  const setDeleteThumbnail = useSetRecoilState(deleteThumbnailState);
  const [imgFile, setImgFile] = useState<string | ArrayBuffer | null>(null);

  const getThumbnailPreview = () => {
    if (thumbnail) {
      return <PreviewContainer src={thumbnail.url} alt="thumbnail" />;
    } else if (imgFile) {
      return <PreviewContainer src={String(imgFile)} alt="thumbnail" />;
    } else {
      return <AiOutlineFileImage style={{ fontSize: '90px' }} />;
    }
  };

  const [uploadThumbnail] = useMutation(UPLOAD_THUMBNAIL);

  const onImageInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let thumbnailImage;
    if (e.target.files && e.target.files.length > 0) {
      thumbnailImage = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(thumbnailImage);
      reader.onloadend = () => {
        setImgFile(reader.result);
      };
    }
    try {
      const result = await uploadThumbnail({
        variables: {
          file: thumbnailImage,
        },
      });

      if (result.data && result.data.uploadThumbnail) {
        setUploadedThumbnailState(Number(result.data.uploadThumbnail.id));
        setThumbnail(result.data.uploadThumbnail);
      }
    } catch (error) {
      openModal({
        type: MODAL_TYPES.error,
        title: '오류 발생',
        message: '이미지 업로드에 실패했습니다. 잠시 후 다시 시도하십시오.',
        error: () => {
          setUploadedThumbnailState(undefined);
          setThumbnail(undefined);
        },
      });
    }
  };

  const onThumbnailDeleteClick = () => {
    const deleteThumbnail = window.confirm('등록된 섬네일을 삭제하시겠습니까?');
    if (deleteThumbnail && thumbnail) {
      setDeleteThumbnail(Number(thumbnail.id));
      setUploadedThumbnailState(undefined);
      setThumbnail(undefined);
      setImgFile(null);
      alert('등록된 섬네일이 삭제되었습니다.');
    }
  };

  return (
    <Container>
      <FormControl mb={5} display={'flex'}>
        <Flex flexDir={'column'}>
          <InputGroup>
            <InputLeftAddon>섬네일</InputLeftAddon>
            <Input
              type="file"
              accept="image/*"
              onChange={onImageInputChange}
              sx={{
                '::file-selector-button': {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: 'none',
                  border: 'none',
                  fontWeight: 'bold',
                },
                '::file-selector-button:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          </InputGroup>
        </Flex>
      </FormControl>
      {getThumbnailPreview()}
      {thumbnail && (
        <Button
          ml={-1}
          w={'fit-content'}
          colorScheme="grey"
          size="sm"
          variant="outline"
          onClick={onThumbnailDeleteClick}
        >
          X
        </Button>
      )}
    </Container>
  );
}

export default ThumbnailInput;

const Container = styled.div`
  display: flex;
  width: fit-content;
  margin-top: 60px;
`;
export const PreviewContainer = styled.img`
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 120px;
  height: 120px;
`;
